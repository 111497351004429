export const locale = {
    lang: 'en',
    data: {
        NAV: {
            APPLICATIONS: 'Setup',
            USER_MANAGEMENT: 'Users Management',
            RUNS: 'Runs',
            INVENTORY: 'Inventory',
            REPORTING: 'Reports',
            INVOICING: 'Invoicing',
            IMPEX: 'Import Data',
            TRACKING: 'Tracking',
            DRIVERMANAGEMENT: {
                TITLE: 'Driver Management'
            },
            ORDER_SCHEDULING: 'Run Planner',
            DB_USER: {
                TITLE: 'DB Users'
            },
            COUNTRY: {
                TITLE: 'Country'
            },
            WORKFLOW: {
                TITLE: 'Workflow'
            },
            TRANSLATION: {
                TITLE: 'Translation'
            },
            GARAGE: {
                TITLE: 'Garage'
            },
            EMAIL_WORKFLOW: {
                TITLE: 'Email Workflow'
            },
            DELETE_WORKFLOW: {
                TITLE: 'Delete Workflow'
            },
            UPDATE_WORKFLOW: {
                TITLE: 'Update Workflow'
            },
            ADVANCE_QUERIES: {
                TITLE: 'Advance Queries'
            },
            CUSTOM_QUERIES: {
                TITLE: 'Custom Queries'
            },
            DATABASE_MANAGEMENT: {
                TITLE: 'Database Management'
            },
            DRIVER_START_DAY_LOG: {
                TITLE: 'Driver Start Day Log'
            },
            DRIVER_MESSAGE: {
                TITLE: 'Driver Messages'
            },
            METSA_ORDER: {
                TITLE: 'Metsa Order Screen'
            },
            VEHICLEMANAGEMENT: {
                TITLE: 'Vehicle Management'
            },
            VAT_TYPE: {
                TITLE: 'VAT Types'
            },
            ATTEMPTED_DELIVERY: {
                TITLE: 'Outstanding Goods Refusal'
            },
            CUSTOMER_TRANSACTIONS: {
                TITLE: 'Customer Balance Ledger'
            },
            PODS: {
                TITLE: 'Proof of Delivery'
            },
            CREDIT_NOTE: {
                TITLE: 'Credit Notes'
            },
            USERS: {
                TITLE: 'Users'
            },
            ORGANISATIONMANAGEMENT: {
                TITLE: 'Organisation Management'
            },
            SCHEDULING: 'Resource Scheduler',
            TARIFF: {TITLE: 'Tariff Management'},
            ZONE_MAP: {TITLE: 'Zone Maps'},
            RESOURCE_PLANNING: 'Resource Planning',
            RESOURCE_EVENT_TYPE: {
                TITLE: 'Resource Event Types'
            },
            RESOURCE_ICONS: {
                TITLE: 'Resource Icons'
            },
            LEG_PLANNING: 'Leg Planning',
            LEG_PLANNER: {
                TITLE: 'Leg Planner'
            },
            HISTORY: 'History',
            MENU: 'Menu',
            DRIVERS: {
                TITLE: 'Drivers'
            },
            ROLES: {
                TITLE: 'Roles'
            },
            NOMINAL_CODE: {
                TITLE: 'Nominal Codes'
            },
            EXTRA_TYPE: {
                TITLE: 'Extra Types'
            },
            ORGANISATION_EXTRA_TYPE: {
                TITLE: 'Organisation Extras'
            },
            BUSINESS_TYPE: {
                TITLE: 'Business Types'
            },
            SCALE: {
                TITLE: 'Scales'
            },
            USER_CONFIGURATION: {
                TITLE: 'User Menu Configuration'
            },
            DRIVER_TYPE: {
                TITLE: 'Driver Types'
            },
            VEHICLE_INSPECTIONS: {
                TITLE: 'Vehicle Inspections'
            },
            SAFETY_PROFILE: {
                TITLE: 'Safety Profiles'
            },
            EXCHANGE_RATE: {
                TITLE: 'Exchange Rates'
            },
            PAYMENTS: {
                TITLE: 'Payments'
            },
            PACKAGE_RETURNS: {
                TITLE: 'Package Returns'
            },
            SITE_VISITS: {
                TITLE: 'Site Visits'
            },
            DAILY_CASH_REPORTS: {
                TITLE: 'Daily Cash Reports'
            },
            SAMPLE: {
                TITLE: 'Branch',
                BADGE: '25'
            },
            ACCOUNTING: {
                TITLE: 'Accounting'
            },
            WAREHOUSE: {
                TITLE: 'Warehouse'
            },
            MAJOR_ACCOUNT_CODE: {
                TITLE: 'Major Account Codes'
            },
            SUPPLIERS: {
                TITLE: 'Suppliers'
            },
            DRIVER: {
                TITLE: 'Drivers'
            },
            SERVICE_PRIORITY_CODE: {
                TITLE: 'Service Priority Codes'
            },
            DEPOSIT_ITEM: {
                TITLE: 'Deposit Items'
            },
            STATEMENT_CODE: {
                TITLE: 'Statement Codes'
            },
            DUNNING_MESSAGE_CODE: {
                TITLE: 'Dunning Message Codes'
            },
            CREDIT_CLASS: {
                TITLE: 'Credit Class'
            },
            SERVICE_LEVEL: {
                TITLE: 'Service Level'
            },
            CREDIT_TERM: {
                TITLE: 'Credit Terms'
            },
            DELIVERY_POINT: {
                TITLE: 'Delivery Points'
            },
            CUSTOM_GOODS_TYPE: {
                TITLE: 'Goods Types'
            },
            STOP_TYPE: {
                TITLE: 'Stop Types'
            },
            PAYMENT_TYPE: {
                TITLE: 'Payment Types'
            },
            CUSTOMER: {
                TITLE: 'Customers'
            },
            CUSTOMER_GROUP: {
                TITLE: 'Customer Groups'
            },
            PRICE_LIST: {
                TITLE: 'Price Lists'
            },
            PRODUCT_CLASS: {
                TITLE: 'Product Class'
            },
            PRODUCT_LIST: {
                TITLE: 'Product Lists'
            },
            FUEL_SURCHARGE_GROUP: {
                TITLE: 'Fuel Sucharge Group'
            },
            PRODUCT_GROUP: {
                TITLE: 'Product Group'
            },
            UNIT_OF_MEASURE: {
                TITLE: 'Unit of Measure'
            },
            PACKAGING: {
                TITLE: 'Packaging'
            },
            PRODUCT_TAX_CATEGORY: {
                TITLE: 'Product Tax Categories'
            },
            PRODUCT: {
                TITLE: 'Products'
            },
            DELIVERY_PACKAGE_TYPE: {
                TITLE: 'Delivery Package Types'
            },
            VEHICLE: {
                TITLE: 'Vehicles'
            },
            VEHICLE_CLASS: {
                TITLE: 'Vehicle Classes'
            },
            TRAILER: {
                TITLE: 'Trailers'
            },
            TRAILER_TYPE: {
                TITLE: 'Trailer Types'
            },
            CORE_REPORTS: {
                TITLE: 'Core Reports'
            },
            ROUTE_TYPE: {
                TITLE: 'Route Types'
            },
            ROUTE: {
                TITLE: 'Routes'
            },
            ORDER: {
                TITLE: 'Orders'
            },
            SALE_ORDER: {
                TITLE: 'Sale Orders'
            },
            ORDER_REJECTION_CODE: {
                TITLE: 'Order Rejection Codes'
            },
            INCOMING_INVENTORY: {
                TITLE: 'Receive/Adjust Inventory'
            },
            LOAD_ORDER: {
                TITLE: 'Load Orders'
            },
            PICK_ORDER: {
                TITLE: 'Pick Orders'
            },
            PACK_ORDER: {
                TITLE: 'Pack Orders'
            },
            DELIVERY_ORDER: {
                TITLE: 'Delivery Orders'
            },
            CUSTOMER_SMS_TEXT: {
                TITLE: 'Customer SMS Texts'
            },
            EMPLOYEE: {
                TITLE: 'Employees'
            },
            EMPLOYEE_GROUP: {
                TITLE: 'Employee Groups'
            },
            HANDHELD_PROFILE: {
                TITLE: 'Handheld Profiles'
            },
            IMPORT_DATA: {
                TITLE: 'Import Data'
            },
            IMPORT_ORDER: {
                TITLE: 'Import Order'
            },
            ROUTE_DISPATCH: {
                TITLE: 'Route Scheduler'
            },
            ROUTE_PLANNER: {
                TITLE: 'Route Planner'
            },
            ROUTE_COMMAND: {
                TITLE: 'Route Monitor'
            },
            INVENTORY_STOCK: {
                TITLE: 'Inventory Stock'
            },
            VEHICLE_UNLOADS: {
                TITLE: 'Vehicle Unloads'
            },
            COMPANY: {
                TITLE: 'Company'
            },
            EXPENSE_TYPE: {
                TITLE: 'Expense Types'
            },
            EXPENSE_PAYMENT_METHOD: {
                TITLE: 'Expense Payment Method'
            },
            EVENT_TYPE: {
                TITLE: 'Event Types'
            },
            REPORT: {
                TITLE: 'All Reports'
            },
            INVOICE: {
                TITLE: 'Invoices'
            },
            EXTRA_INVOICE: {
                TITLE: 'Extra Invoice'
            },
            SUBCONTRACTOR_INVOICE: {
                TITLE: 'Subcontractor Invoice'
            },
            HAULIER_ORDER_ALL: {
                TITLE: 'All Orders'
            },
            HAULIER_ORDER_COLLECTIONS: {
                TITLE: 'Collections'
            },
            HAULIER_ORDER_COLLECTIONS_OLD: {
                TITLE: 'Collections'
            },
            HAULIER_ORDER_DELIVERIES: {
                TITLE: 'Deliveries'
            },
            HAULIER_ORDER_DELIVERIES_OLD: {
                TITLE: 'Deliveries'
            },
            LOCATION_POINT: {
                TITLE: 'Location Points'
            },
            LOCATION_POINT_AWAITING_INTEGRATION: {
                TITLE: 'Points for Integration'
            },
            HAULIER_ORDER_UNPLANNED: {
                TITLE: 'Orders for Planning'
            },
            ORGANISATION: {
                TITLE: 'Organisations'
            },
            CONTACT_INFO: {
                TITLE: 'Contact Info'
            },
            STOCK_TAKES: {
                TITLE: 'Blind Stock Takes'
            },
            PAYMENT: {
                TITLE: 'Payments'
            },
            RUN: {
                TITLE: 'Run List'
            },
            PLANNING: 'Planning',
            ADMIN: 'Admin',
            TOUR_PLAN: {
                TITLE: 'Tour Planning'
            },
            MAP_BASED_PLANNING: {
                TITLE: 'Map-Based Planning'
            },
            MAP_BASED_PLANNING_OLD: {
                TITLE: 'Map-Based Planning'
            },
            TP_ASSET_PROFILE: {
                TITLE: 'Asset profiles'
            },
            TP_VEHICLE_SKILL: {
                TITLE: 'Vehicle Skills'
            },
            ADMIN_REINDEX: {
                TITLE: 'Search Re-index'
            },
            TP_VEHICLE_TYPE: {
                TITLE: 'Tp Vehicle Types'
            },
            TOUR_PLANNER: {
                TITLE: 'TourPlanner'
            },
            TRAFFIC_SHEET: {
                TITLE: 'Traffic Sheet'
            },
            EXPENSES: {
                TITLE: 'Driver Expenses'
            },
            DASHBOARD: {
                TITLE: 'Dashboard'
            },
            ORDER_SUBCONTRACT: {
                TITLE: 'Orders Pending Subcontracting'
            },
            ORDER_SUBCONTRACTED: {
                TITLE: 'Subcontracted Orders'
            },
            DAILY_MANIFEST: {
                TITLE: 'Daily Manifest'
            },
            DEDICATED_VEHICLE_VIEW: {
                TITLE: 'Dedicated Vehicle View'
            },
            ARRIVAL_BOARD: {
                TITLE: 'Arrival Board'
            },
            CONTROL_AREA: {
                TITLE: 'Control Areas'
            },
            TRAFFIC_AREA: {
                TITLE: 'Traffic Areas'
            },
            TEAMS: {
                TITLE: 'Teams'
            },
            LATE_REASON: {
                TITLE: 'Late Reasons'
            },
            DEVIATION_REASON: {
                TITLE: 'Deviation Reasons'
            },
            CLAUSED_DELIVERY_REASON: {
                TITLE: 'Claused Delivery Reasons'
            },
            APP_REQUESTS: {
                TITLE: 'App Requests'
            },
            ASYNC_REQUESTS: {
                TITLE: 'Async Requests'
            },
            LOCALCLIENT: {
                TITLE: 'Local Client'
            },
            ACCOUNTS_PACKAGE: {
                TITLE: 'Accounts Package Setup'
            },
            IMAGE_MANAGEMENT: {
                TITLE: 'Image Management'
            },
            // TASK_MANAGEMENT: {
            //     TITLE: 'Task Management'
            // },
            // FILE_MANAGEMENT: {
            //     TITLE: 'File Management'
            // },
            DRIVER_SUB_TYPES: {
                TITLE: 'Driver Sub Types'
            },
            FLEETVIEW: {
                TITLE: 'Fleet View'
            },
            MANIFEST: {
                TITLE: 'Created Manifests'
            },
            LOADING_SHEET: {
                TITLE: 'Loading Sheet'
            },
            CREATE_MANIFEST: {
                TITLE: 'Manifests'
            },
            DC_DASHBOARD: {
                TITLE: 'DC Dashboard'
            },
            SUPER_CONFIG: {TITLE: 'Super Config'},
            AUDIT: {
                TITLE: 'Audits'
            },
            MAIL_AUDIT: {
                TITLE: 'Mail Audit'
            },
            POD_AUDIT: {
                TITLE: 'POD Audit'
            },
            REQUESTS: {
                TITLE: 'Requests'
            },
            READY_TO_INVOICE: {
                TITLE: 'Create Pre-Invoice Batch'
            },
            PRE_INVOICE_BATCH: {
                TITLE: 'Pre Invoice Batches'
            },
            SHIFT_PLANNING: {
                TITLE: 'Shift Planner'
            },
            OUSTANDING_PODS: {
                TITLE: 'Outstanding Pods'
            },
            SHIFT_TYPES: {
                TITLE: 'Shift Types'
            },
            AVAILABLE_DRIVER_REPORT: {
                TITLE: 'Available Drivers'
            },
            MARK_READY_TO_INVOICE: {
                TITLE: 'Flag Orders as Ready To Invoice'
            },
            MULTI_BATCH_INVOICE: {
                TITLE: 'Multi Batch Invoice'
            },
            OUTSTANDING_CALL_INS: {
                TITLE: 'Outstanding Call-Ins'
            },
            CLIENT_REVENUE_REPORT: {
                TITLE: 'Client Revenue'
            },
            CLIENT_WORK_REPORT: {
                TITLE: 'Client Work'
            },
            REVENUE_REPORT: {
                TITLE: 'Revenue Report'
            },
            ETA_ARRIVALS_BOARD: {
                TITLE: 'ETA Arrivals Board'
            },
            SUBCONTRACTED_WORK_REPORT: {
                TITLE: 'Subcontracted Work Report'
            },
            AUDIT_LOG: {
                TITLE: 'Audit Log'
            },
            TENANT_MANAGEMENT: {
                TITLE: 'Tenant Management'
            },
            TENANTS: {
                TITLE: 'Tenants'
            },
            PROFILES: {
                TITLE: 'Profiles'
            },
            BACKUPS: {
                TITLE: 'Backups'
            },
            RESTORES: {
                TITLE: 'Restores'
            },
            EXECUTIONS: {
                TITLE: 'Executions'
            },
            HAULIER_ORDER_PENDING_APPROVAL: {
                TITLE: 'Pending Orders'
            },
            ORDERS: {
                TITLE: 'Orders'
            },
            HAULIER_ORDER_AWAITING_INTEGRATION: {
                TITLE: 'Orders Awaiting Integration'
            },
            HAULIER_ORDER_FAILED_INTEGRATION: {
                TITLE: 'Orders Failed Integration'
            },
            DRIVER_WORK_REPORT: {
                TITLE: 'Driver Revenue Report'
            },
            APP_MANAGEMENT: {
                TITLE: 'App Management'
            },
            APP_VERSION: {
                TITLE: 'App Versions'
            },
            COMPLETED_RUN_REPORT: {
                TITLE: "Completed Run Call In's"
            },
            ALL_RUNS_FOR_CLIENTS_REPORT: {
                TITLE: "All Runs For Client's"
            },
            AGENCY_DRIVER_REPORT: {
                TITLE: 'Agency Driver Report'
            },
            SUBCONTRACT_HISTORY_REPORT: {
                TITLE: 'Subcontract History Report'
            },
            CUSTOMER_REPORTS: {
                TITLE: 'Customer Reports'
            },
            DRIVER_REPORTS: {
                TITLE: 'Driver Reports'
            },
            SUBCONTRACTOR_REPORTS: {
                TITLE: 'Subcontractor Reports'
            },
            PRICING_REPORT: {
                TITLE: 'Pricing Report'
            },
            EMPTY_RUNNING_REPORT: {
                TITLE: 'Empty Running Report'
            },
            EXTRA_REVENUE_REPORT: {
                TITLE: 'Extra Revenue Report'
            },
            INTEGRATION_MANAGEMENT: {
                TITLE: 'Integration Management'
            },
            INTEGRATION_MAPPINGS: {
                TITLE: 'Integration Mappings'
            },
            SYSTEM_SURCHARGES: {
                TITLE: 'System Fuel Surcharges'
            },
            RESOURCE_WORK_REPORT: {
                TITLE: 'Resource Work Report'
            },
            DRIVER_REQUEST_TYPES: {
                TITLE: 'Driver Request Types'
            },
            DRIVER_REQUESTS: {
                TITLE: 'Driver Requests'
            },
            HAULIER_ORDER_CREATE: {
                TITLE: 'Create Order(s)'
            },
            DRIVER_STRIKE_REPORT: {
                TITLE: 'Driver Strike Report'
            },
            SUBCONTRACTOR_PROFITABILITY_REPORT: {
                TITLE: 'Subcontractor Profitability Report'
            },
            MULTI_TRUNK: {
                TITLE: 'Multi-Trunk Setup'
            },
            DRIVER_BREAK: {
                TITLE: 'Driver Breaks'
            },
            NEW_TRAFFIC_SHEET: {
                TITLE: 'New Traffic Sheet (Beta)'
            },
            ACKNOWLEDGE_REPORT: {
                TITLE: 'Acknowledge Report'
            }
        }
    }
};

import {Invoice} from 'app/blocks/model/invoice.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams, HttpHeaders} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IInvoice} from 'app/blocks/model/invoice.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {DEFAULT_PAGE_SIZE, SERVER_API_URL} from 'app/app.constants';
// import { IExportSetting } from '../../model/export-settings.model';
import {IApplyPaymentDTO} from 'app/blocks/model/payment.model';
import {ListViewParams} from 'app/blocks/util/list-view-params';
import {DefaultVat, IDefaultVat} from 'app/blocks/model/default-vat.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DefaultVatService extends EntityCrudService<IDefaultVat> {
    constructor(http: HttpClient) {
        super(http, Resource.DEFAULT_VAT, (obj?) => new DefaultVat(obj));
    }

    saveDefaultVats = (defaultVats: any): Promise<HttpResponse<any>> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/default-vats', defaultVats, {
                observe: 'response'
            })
            .toPromise();
    };

    getDefaultVats = (): Promise<any> => {
        return this.http.get(SERVER_API_URL + 'api/default-vats/').toPromise();
    };

    getAllDefaultVats = (): Observable<any> => {
        return this.http.get(SERVER_API_URL + 'api/default-vats/');
    };
}

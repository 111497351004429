import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {AccountService} from 'app/core/auth/account.service';
import {Store} from '@ngxs/store';
import {GetConfig} from 'app/blocks/store/actions/common-actions';
import {CommonUtilsService} from 'app/common/common-utils-service';
import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';

@Injectable()
export class Principal {
    private userIdentity: any;
    private contactInfoId: number;
    private organisationIds: number[];
    private authenticated = false;
    private authenticationState = new BehaviorSubject<any>(null);

    constructor(
        private account: AccountService,
        private store: Store,
        private commonUtilsService: CommonUtilsService,
        private newrelicAgent?: BrowserAgent
    ) {
        this.authenticationState.subscribe((identity) => {
            if (identity) {
                this.newrelicAgent?.setCustomAttribute('tenant', identity.tenantId);
            }
        });
    }

    authenticate(identity): void {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.userIdentity.authorities.includes(authorities[i])) {
                return true;
            }
        }

        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            (id) => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    identity(force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account
            .get()
            .toPromise()
            .then((response) => {
                const account = response.body;
                if (account) {
                    this.userIdentity = account;
                    this.authenticated = true;
                    this.contactInfoId = account['contactInfoId'];
                    this.organisationIds = account['organisationIds'];
                    this.store.dispatch(new GetConfig());
                } else {
                    this.userIdentity = null;
                    this.authenticated = false;
                }
                this.authenticationState.next(this.userIdentity);
                return this.userIdentity;
            })
            .catch((err) => {
                this.userIdentity = null;
                this.authenticated = false;
                this.authenticationState.next(this.userIdentity);
                return null;
            });
    }

    isAuthenticated(): boolean {
        console.log('[Principal] isAuthenticated: ' + this.authenticated);
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getOrganisationIds(): number[] {
        return this.organisationIds;
    }

    getContactInfoId(): number {
        return this.contactInfoId;
    }

    isUserCustomer(): boolean {
        return this.userIdentity && this.userIdentity.authorities.includes('ROLE_CUSTOMER');
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    getImageUrl(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }
}

import {AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {Store} from '@ngxs/store';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {Resource} from 'app/constants/resource';
import {locale as english} from 'app/main/private/common/check-route-modal/i18n/en';
import {locale as french} from 'app/main/private/common/check-route-modal/i18n/fr';

import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {TrackingService} from 'app/blocks/service/api/tracking.service';
import {LegService} from 'app/blocks/service/api/leg-service';
import {forEach} from 'lodash';
import {DriverService} from 'app/blocks/service/api/driver.service';
import {VehicleService} from 'app/blocks/service/api/vehicle.service';
import {faTruck} from '@fortawesome/free-solid-svg-icons';
import {ILeg} from 'app/blocks/model/leg.model';
import {IStop} from 'app/blocks/model/stop.model';
import {formatToTimezone} from 'app/common/utils/date-utils';
import {BehaviorSubject, Observable, from, of} from 'rxjs';
import {CurrencyService} from 'app/blocks/service/api/currency.service';
import {FuseThemeOptionsComponent} from '@fuse/components/theme-options/theme-options.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LocationPoint} from 'app/blocks/model/location-point.model';
import {LocationPointResource} from 'app/blocks/resource/location-point-resource';
import {CheckRouteService} from 'app/blocks/service/api/check-route.service';
import {
    autocompleteSouthWestBoundLat,
    autocompleteSouthWestBoundLng,
    autocompleteNorthEastBoundLat,
    autocompleteNorthEastBoundLng,
    googlePlacesCountryMapping
} from 'app/constants/map-constants';
import {environment} from 'environments/environment';
import {roundTo} from 'app/blocks/util/conversion-util';
import {NamedListService} from 'app/blocks/service/named-list.service';

declare let H: any;
let checkRouteModalComponent: CheckRouteModalComponent;
let field;

@Component({
    selector: 'check-route-modal',
    templateUrl: './check-route-modal.component.html',
    styleUrls: ['./check-route-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        {
            provide: TRANSLATION_BASE,
            useValue: 'checkRouteModal'
        }
    ]
})
export class CheckRouteModalComponent implements OnInit, AfterViewInit {
    readonly predictedRouteColour: string = '#F2DB12';

    @ViewChild('map', {static: true}) public mapElement: ElementRef;

    public map: any;
    private platform: any;

    private _apiKey = environment.heremapsTourPlanningCredentials.apiKey;

    public lat: any = '53.8008';
    public lng: any = '-1.5491';

    public ui: any;

    mileageBS$ = new BehaviorSubject(0);
    rateBS$ = new BehaviorSubject(0);
    costPerMileBS$ = new BehaviorSubject(0);

    locationPoints$;

    form: FormGroup;

    collectionLat;
    collectionLng;
    collectionPostZip;

    deliveryLat;
    deliveryLng;
    deliveryPostZip;

    searchTypes$ = of([
        {
            label: 'Point',
            value: 'POINT'
        },
        {
            label: 'Address',
            value: 'ADDRESS'
        }
    ]);

    public collectAutoComplete: google.maps.places.Autocomplete;
    public deliveryAutoComplete: google.maps.places.Autocomplete;

    priceLists$;

    constructor(
        @Inject(NZ_MODAL_DATA) public modalData: any,
        _store: Store,
        _route: ActivatedRoute,
        public currencyService: CurrencyService,
        public dialogRef: NzModalRef,
        public _domSanitizer: DomSanitizer,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fb: FormBuilder,
        private locationPointResource: LocationPointResource,
        private checkRouteService: CheckRouteService,
        private namedService: NamedListService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        this.loadHereMaps();

        this.form = this._fb.group({
            collectionSearchType: ['POINT'],
            collectionPoint: [, Validators.required],
            deliverySearchType: ['POINT', Validators.required],
            deliveryPoint: [, Validators.required],
            collectionAddressSearch: [],
            deliveryAddressSearch: [],
            priceListId: []
        });
    }

    ngOnInit(): void {
        this.priceLists$ = this.namedService.for(Resource.PRICE_LISTS);
    }

    loadHereMaps(): void {
        this.platform = new H.service.Platform({
            apikey: this._apiKey,
            useHTTPS: true
        });
    }

    ngAfterViewInit(): void {
        const pixelRatio = window.devicePixelRatio || 1;
        const defaultLayers = this.platform.createDefaultLayers({
            tileSize: pixelRatio === 1 ? 256 : 512,
            ppi: pixelRatio === 1 ? undefined : 320
        });

        this.map = new H.Map(this.mapElement.nativeElement, defaultLayers.vector.normal.map, {
            pixelRatio: pixelRatio,
            zoom: 5.5
            // padding: { top: 50, left: 50, bottom: 50, right: 50 },
        });
        new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);

        this.ui.getControl('mapsettings').setDisabled(false);
        this.ui.getControl('zoom').setDisabled(false);
        this.ui.getControl('scalebar').setDisabled(false);

        const mapSettings = this.ui.getControl('mapsettings');
        const zoom = this.ui.getControl('zoom');
        const scalebar = this.ui.getControl('scalebar');

        mapSettings.setAlignment('top-left');
        zoom.setAlignment('top-left');
        scalebar.setAlignment('top-left');

        this.map.setCenter({lat: this.lat, lng: this.lng});
    }

    getPolylineObject(lineString, colour: string): any {
        const polyline = new H.map.Polyline(lineString, {
            style: {
                lineWidth: 4,
                strokeColor: colour
            }
        });
        return polyline;
    }

    showLeg(line): void {
        const estimatedRoute = new H.map.Group();

        const lines = [];
        line.split(',').forEach((line) => {
            lines.push(new H.geo.LineString.fromFlexiblePolyline(line));
        });
        const multiLineString = new H.geo.MultiLineString(lines);
        estimatedRoute.addObject(this.getPolylineObject(multiLineString, this.predictedRouteColour));

        this.map.addObject(estimatedRoute);
        this.map.getViewModel().setLookAtData({
            bounds: estimatedRoute.getBoundingBox()
        });
    }

    addMarker(lat: number, lng: number, index): any {
        const markerGroup = new H.map.Group();

        const iconHtml = `<div style="cursor:pointer; 
                width:30px;
                text-align:center;
                padding:5px;
                color:white;
                height:auto;
                border-radius:50%;
                background:#Ff1c00;
                border: 1px solid white;">
                ${index}
            </div>`;

        const dotIcon = new H.map.DomIcon(iconHtml, {anchor: {x: 0, y: 0}});

        const marker = new H.map.DomMarker(
            {
                lat: lat,
                lng: lng
            },
            {icon: dotIcon, bubbleOpen: false}
        );

        markerGroup.addObject(marker);
        this.map.addObject(markerGroup);
    }

    searchLocationPoints = (term): Observable<any[]> => {
        return from(
            this.locationPointResource.api
                .search({
                    page: 0,
                    query: term,
                    size: 50,
                    sort: 'name,asc',
                    deleted: false
                })
                .then((response) => {
                    return response.map((locationPoint) => new LocationPoint(locationPoint));
                })
        );
    };

    collectionPointChanged($event) {
        this.collectionLat = $event.contactInfo?.address?.latitude;
        this.collectionLng = $event.contactInfo?.address?.longitude;
        this.collectionPostZip = $event.contactInfo?.address?.postCodeZip;
        this.getRoute();
    }

    deliveryPointChanged($event) {
        this.deliveryLat = $event.contactInfo?.address?.latitude;
        this.deliveryLng = $event.contactInfo?.address?.longitude;
        this.deliveryPostZip = $event.contactInfo?.address?.postCodeZip;
        this.getRoute();
    }

    getRoute() {
        if (!this.collectionLat || !this.collectionLng || !this.deliveryLng || !this.deliveryLat) {
            return;
        }
        const values = {
            collectionLat: this.collectionLat,
            collectionLng: this.collectionLng,
            collectionPostZip: this.collectionPostZip,
            deliveryLng: this.deliveryLng,
            deliveryLat: this.deliveryLat,
            deliveryPostZip: this.deliveryPostZip
        };
        if (this.form.value.priceListId) {
            values['priceListId'] = this.form.value.priceListId;
        }
        this.checkRouteService.checkRoute(values).then((response) => {
            this.map.removeObjects(this.map.getObjects());

            this.mileageBS$.next(response.body.mileage);

            this.addMarker(this.collectionLat, this.collectionLng, 1);
            this.addMarker(this.deliveryLat, this.deliveryLng, 2);

            this.rateBS$.next(response.body.rate);
            this.costPerMileBS$.next(roundTo(response.body.rate / response.body.mileage, 2));

            this.showLeg(response.body.polyline);
        });
    }

    initAutoComplete(addressField): void {
        field = addressField;
        if (addressField == 'COLLECT') {
            if (!this.collectAutoComplete) {
                const southWest = new google.maps.LatLng(autocompleteSouthWestBoundLat, autocompleteSouthWestBoundLng);
                const northEast = new google.maps.LatLng(autocompleteNorthEastBoundLat, autocompleteNorthEastBoundLng);
                const bounds = new google.maps.LatLngBounds(southWest, northEast);
                let fields = document.getElementsByName('collectionAddressSearch');
                if (!fields || fields.length != 1) {
                    return;
                }

                const autoCompleteField = fields[0] as HTMLInputElement;
                this.collectAutoComplete = new google.maps.places.Autocomplete(autoCompleteField, {
                    strictBounds: true,
                    bounds: bounds
                });
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                checkRouteModalComponent = this;
                this.collectAutoComplete.addListener('place_changed', this.fillInAddress);
            }
        } else {
            if (!this.deliveryAutoComplete) {
                const southWest = new google.maps.LatLng(autocompleteSouthWestBoundLat, autocompleteSouthWestBoundLng);
                const northEast = new google.maps.LatLng(autocompleteNorthEastBoundLat, autocompleteNorthEastBoundLng);
                const bounds = new google.maps.LatLngBounds(southWest, northEast);
                let fields = document.getElementsByName('deliveryAddressSearch');
                if (!fields || fields.length != 1) {
                    return;
                }
                const autoCompleteField = fields[0] as HTMLInputElement;
                this.deliveryAutoComplete = new google.maps.places.Autocomplete(autoCompleteField, {
                    strictBounds: true,
                    bounds: bounds
                });
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                checkRouteModalComponent = this;
                this.deliveryAutoComplete.addListener('place_changed', this.fillInAddress);
            }
        }
    }

    fillInAddress(): void {
        let me: CheckRouteModalComponent;
        let autoComplete: google.maps.places.Autocomplete;
        if (checkRouteModalComponent) {
            me = checkRouteModalComponent;
            autoComplete = field == 'COLLECT' ? checkRouteModalComponent.collectAutoComplete : checkRouteModalComponent.deliveryAutoComplete;
        }
        if (autoComplete) {
            const place = autoComplete.getPlace();
            if (place) {
                if (place.geometry) {
                    if (place.geometry.location) {
                        if (field) {
                            if (field == 'COLLECT') {
                                me.collectionLat = place.geometry.location.lat();
                                me.collectionLng = place.geometry.location.lng();
                            } else {
                                me.deliveryLat = place.geometry.location.lat();
                                me.deliveryLng = place.geometry.location.lng();
                            }
                        }
                    }
                }
                if (place.address_components) {
                    for (let i = 0; i < place.address_components.length; i++) {
                        const component = place.address_components[i];
                        const addressType = component.types[0];
                        if (addressType == 'postal_code') {
                            if (field == 'COLLECT') {
                                me.collectionPostZip = component.short_name;
                            } else {
                                me.deliveryPostZip = component.short_name;
                            }
                        }
                    }
                }
            }
            me.getRoute();
        }
    }
}

export class TourPlanParameters {
    static readonly DEFAULT_SHIFT_OPTIMIZATION_UNIT = 60;

    public orderIds: number[];
    public startLocation: number | null;
    public endLocation: number | null;
    public shiftStart: string;
    public shiftEnd: string;
    public vehicleTypes: number[];
    public ignoreTimes: boolean;
    public trafficRules: TourPlanTrafficRules;
    public tourPlanningMode: TourPlanningMode;
    public followTerritoriesStrictly: boolean;
    public planOrdersAlreadyAssignedToRun?: boolean;
    public useMaximumVehicles?: boolean;
    public optimizeShiftEndTime? = false;
    public shiftOptimizationUnit?: number;
    public deleteExistingRuns? = false;
    public useBetaEnv? = false;
    public useTourPlanningV2? = false;

    constructor(tourPlanParameters?: any) {
        if (tourPlanParameters) {
            this.orderIds = tourPlanParameters.orderIds ?? [];
            this.startLocation = tourPlanParameters.startLocation;
            this.endLocation = tourPlanParameters.endLocation;
            this.shiftStart = tourPlanParameters.shiftStart;
            this.shiftEnd = tourPlanParameters.shiftEnd;
            this.vehicleTypes = tourPlanParameters.vehicleTypes ?? [];
            this.ignoreTimes = tourPlanParameters.ignoreTimes === true;
            this.trafficRules = tourPlanParameters.trafficRules;
            this.tourPlanningMode = tourPlanParameters.tourPlanningMode;
            this.followTerritoriesStrictly = tourPlanParameters.followTerritoriesStrictly === true;
            this.planOrdersAlreadyAssignedToRun = tourPlanParameters.planOrdersAlreadyAssignedToRun === true;
            this.useMaximumVehicles = tourPlanParameters.useMaximumVehicles === true;
            this.optimizeShiftEndTime = tourPlanParameters.optimizeShiftEndTime === true;
            this.shiftOptimizationUnit = tourPlanParameters.shiftOptimizationUnit ?? TourPlanParameters.DEFAULT_SHIFT_OPTIMIZATION_UNIT;
            this.useBetaEnv = tourPlanParameters.useBetaEnv === true;
            this.deleteExistingRuns = tourPlanParameters.deleteExistingRuns === true;
            this.useBetaEnv = tourPlanParameters.useBetaEnv ?? false;
            this.useTourPlanningV2 = tourPlanParameters.useTourPlanningV2 ?? false;
        }
    }
}

export enum TourPlanTrafficRules {
    LIVEORHISTORICAL,
    HISTORICALONLY,
    AUTOMATIC
} // must be CAPS
export enum TourPlanningMode {
    REAL_TIME,
    DEPOT_BASED
}

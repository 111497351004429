import {FuseNavigation} from '@fuse/types';
import {
    faTruckMoving,
    faTruckPickup,
    faFileUpload,
    faHardHat,
    faPallet,
    faRoute,
    faShippingFast,
    faWrench,
    faFileContract,
    faIdBadge,
    faExchangeAlt,
    faTasks,
    faBoxOpen,
    faPercent,
    faBuilding,
    faMapPin,
    faMoneyCheckAlt,
    faBell,
    faTrailer,
    faCaravan,
    faStar,
    faHourglassHalf,
    faAsterisk,
    faGlasses,
    faLocationArrow,
    faTruck,
    faAddressCard
} from '@fortawesome/free-solid-svg-icons';
import {BarChartOutline} from '@ant-design/icons-angular/icons';

export const navigation: FuseNavigation[] = [
    {
        id: 'planning',
        title: 'Planning',
        translate: 'NAV.PLANNING',
        type: 'group',
        children: [
            {
                id: 'haulier-order-all',
                title: 'Orders',
                translate: 'NAV.HAULIER_ORDER_ALL.TITLE',
                type: 'item',
                url: '/orders/haulier-orders',
                faIcon: faPallet
            },
            {
                id: 'haulier-order-collections',
                title: 'Collections',
                translate: 'NAV.HAULIER_ORDER_COLLECTIONS.TITLE',
                type: 'item',
                url: '/orders/haulier-order-collections',
                faIcon: faPallet
            },
            {
                id: 'haulier-order-deliveries',
                title: 'Deliveries',
                translate: 'NAV.HAULIER_ORDER_DELIVERIES.TITLE',
                type: 'item',
                url: '/orders/haulier-order-deliveries',
                faIcon: faPallet
            },
            {
                id: 'run',
                title: 'Run List',
                translate: 'NAV.RUN.TITLE',
                type: 'item',
                url: '/runs/runs',
                faIcon: faShippingFast
            },
            {
                id: 'shift-planner',
                title: 'Shift Planner',
                translate: 'NAV.SHIFT_PLANNING.TITLE',
                type: 'item',
                url: '/resource-planning/shift-planning',
                faIcon: faGlasses
            },
            {
                id: 'traffic-sheet',
                title: 'Traffic Sheet',
                translate: 'NAV.TRAFFIC_SHEET.TITLE',
                type: 'item',
                url: '/traffic-sheets/traffic-sheet',
                faIcon: faStar
            },
            {
                id: 'tour-planner',
                title: 'Tour Planners',
                translate: 'NAV.TOUR_PLANNER.TITLE',
                type: 'item',
                url: '/tour-planners/tour-planners',
                faIcon: faStar
            },
            {
                id: 'order-scheduler',
                title: 'Run Planner',
                translate: 'NAV.ORDER_SCHEDULING',
                type: 'item',
                url: '/resource-planning/order-scheduling',
                faIcon: faGlasses
            },
            {
                id: 'manifest',
                title: 'Created Manifest',
                translate: 'NAV.MANIFEST.TITLE',
                type: 'item',
                url: '/manifests/manifests',
                faIcon: faStar
            },
            {
                id: 'create-manifest',
                title: 'Manifest',
                translate: 'NAV.CREATE_MANIFEST.TITLE',
                type: 'item',
                url: '/setup/create-manifest',
                faIcon: faStar
            },
            {
                id: 'expenses',
                title: 'Driver Expenses',
                translate: 'NAV.EXPENSES.TITLE',
                type: 'item',
                url: '/expense/expenses',
                faIcon: faStar
            },
            {
                id: 'fleetview',
                title: 'Fleet View',
                translate: 'NAV.FLEETVIEW.TITLE',
                type: 'item',
                url: '/fleetviews',
                faIcon: faLocationArrow
            }
        ]
    },
    {
        id: 'Reporting',
        title: 'Reporting',
        translate: 'NAV.REPORTING',
        type: 'group',
        children: [
            {
                id: 'core-reports',
                title: 'Core Reports',
                translate: 'NAV.CORE_REPORTS.TITLE',
                type: 'item',
                faIcon: faFileContract,
                url: '/analytics/reports'
            },
            {
                id: 'vehicleInspections',
                title: 'Vehicle Inspections',
                translate: 'NAV.VEHICLE_INSPECTIONS.TITLE',
                type: 'item',
                faIcon: faWrench,
                url: '/history/vehicle-inspections'
            },
            {
                id: 'pods',
                title: 'Proof of Delivery',
                translate: 'NAV.PODS.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/history/pods'
            }
        ]
    },
    {
        id: 'appRequests',
        title: 'App Requests',
        translate: 'NAV.APP_REQUESTS.TITLE',
        type: 'group',
        children: [
            {
                id: 'app-requests',
                title: 'App Requests',
                translate: 'NAV.APP_REQUESTS.TITLE',
                type: 'item',
                url: '/history/app-requests',
                icon: 'bar_chart'
            }
        ]
    },
    {
        id: 'Setup',
        title: 'Setup',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'driver-management',
                title: 'Driver Management',
                translate: 'NAV.DRIVERMANAGEMENT.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'driver',
                        title: 'Drivers',
                        translate: 'NAV.DRIVERS.TITLE',
                        type: 'item',
                        url: '/setup/drivers',
                        faIcon: faTruck
                    },
                    {
                        id: 'handheld-profile',
                        title: 'Handheld Profiles',
                        translate: 'NAV.HANDHELD_PROFILE.TITLE',
                        type: 'item',
                        faIcon: faIdBadge,
                        url: '/setup/handheld-profiles'
                    }
                ]
            },
            {
                id: 'vehicle-management',
                title: 'Vehicle Management',
                translate: 'NAV.VEHICLEMANAGEMENT.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'vehicle',
                        title: 'Vehicles',
                        translate: 'NAV.VEHICLE.TITLE',
                        type: 'item',
                        icon: 'local_shipping',
                        url: '/setup/vehicles',
                        faIcon: faTruckMoving
                    },
                    {
                        id: 'vehicle-class',
                        title: 'Vehicle Classes',
                        translate: 'NAV.VEHICLE_CLASS.TITLE',
                        type: 'item',
                        icon: 'style',
                        url: '/setup/vehicle-classes',
                        faIcon: faTruckPickup
                    },
                    {
                        id: 'trailer',
                        title: 'Trailers',
                        translate: 'NAV.TRAILER.TITLE',
                        type: 'item',
                        url: '/setup/trailers',
                        faIcon: faTrailer
                    },
                    {
                        id: 'trailer-type',
                        title: 'Trailer Types',
                        translate: 'NAV.TRAILER_TYPE.TITLE',
                        type: 'item',
                        icon: 'style',
                        url: '/setup/trailer-types',
                        faIcon: faCaravan
                    }
                ]
            },
            {
                id: 'tariff',
                title: 'Tariff Management',
                translate: 'NAV.TARIFF.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'scale',
                        title: 'Scales',
                        translate: 'NAV.SCALE.TITLE',
                        type: 'item',
                        url: '/tariff/scales',
                        faIcon: faStar
                    },
                    {
                        id: 'fuel-surcharge-group',
                        title: 'Fuel Surcharge Groups',
                        translate: 'NAV.FUEL_SURCHARGE_GROUP.TITLE',
                        type: 'item',
                        url: '/tariff/fuel-surcharge-groups',
                        faIcon: faPercent
                    },
                    // {
                    //     id: "extra-type",
                    //     title: "Extra Types",
                    //     translate: "NAV.EXTRA_TYPE.TITLE",
                    //     type: "item",
                    //     url: "/tariff/extra-types",
                    //     faIcon: faStar,
                    // },
                    // {
                    //     id: "service-level",
                    //     title: "Service Levels",
                    //     translate: "NAV.SERVICE_LEVEL.TITLE",
                    //     type: "item",
                    //     url: "/tariff/service-levels",
                    //     faIcon: faTasks,
                    // },
                    {
                        id: 'nominal-code',
                        title: 'Nominal Codes',
                        translate: 'NAV.NOMINAL_CODE.TITLE',
                        type: 'item',
                        url: '/tariff/nominal-codes',
                        faIcon: faStar
                    },
                    // {
                    //     id: "custom-goods-type",
                    //     title: "Custom Goods Types",
                    //     translate: "NAV.CUSTOM_GOODS_TYPE.TITLE",
                    //     type: "item",
                    //     url: "/tariff/custom-goods-types",
                    //     faIcon: faBoxOpen,
                    // },
                    {
                        id: 'price-list',
                        title: 'Price Lists',
                        translate: 'NAV.PRICE_LIST.TITLE',
                        type: 'item',
                        url: '/tariff/price-lists',
                        faIcon: faStar
                    },
                    {
                        id: 'zone-map',
                        title: 'Zone Map',
                        translate: 'NAV.ZONE_MAP.TITLE',
                        type: 'item',
                        url: '/tariff/zone-maps',
                        faIcon: faStar
                    }
                ]
            },
            {
                id: 'organisation-management',
                title: 'Organisation Management',
                translate: 'NAV.ORGANISATIONMANAGEMENT.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'exchange-rate',
                        title: 'Exchange Rates',
                        translate: 'NAV.EXCHANGE_RATE.TITLE',
                        type: 'item',
                        url: '/setup/exchange-rates',
                        faIcon: faExchangeAlt
                    },
                    {
                        id: 'country',
                        title: 'Country',
                        translate: 'NAV.COUNTRY.TITLE',
                        type: 'item',
                        url: '/setup/country',
                        faIcon: faPallet
                    },
                    {
                        id: 'service-level',
                        title: 'Service Levels',
                        translate: 'NAV.SERVICE_LEVEL.TITLE',
                        type: 'item',
                        url: '/setup/service-levels',
                        faIcon: faTasks
                    },
                    {
                        id: 'custom-goods-type',
                        title: 'Goods Types',
                        translate: 'NAV.CUSTOM_GOODS_TYPE.TITLE',
                        type: 'item',
                        url: '/tariff/custom-goods-types',
                        faIcon: faBoxOpen
                    },
                    // {
                    //     id: 'extra',
                    //     title: 'Extras',
                    //     translate: 'NAV.EXTRA.TITLE',
                    //     type: 'item',
                    //     url: '/setup/extras',
                    //     faIcon: faStar
                    // },
                    {
                        id: 'fuel-surcharge-group',
                        title: 'Fuel Surcharge Groups',
                        translate: 'NAV.FUEL_SURCHARGE_GROUP.TITLE',
                        type: 'item',
                        url: '/setup/fuel-surcharge-groups',
                        faIcon: faPercent
                    },
                    {
                        id: 'organisation',
                        title: 'Organisations',
                        translate: 'NAV.ORGANISATION.TITLE',
                        type: 'item',
                        url: '/setup/organisations',
                        faIcon: faBuilding
                    },
                    {
                        id: 'contact-info',
                        title: 'Contact Info',
                        translate: 'NAV.CONTACT_INFO.TITLE',
                        type: 'item',
                        url: '/setup/contact-info',
                        faIcon: faAddressCard
                    },
                    {
                        id: 'location-point',
                        title: 'Location Points',
                        translate: 'NAV.LOCATION_POINT.TITLE',
                        type: 'item',
                        url: '/setup/location-points',
                        faIcon: faMapPin
                    },
                    {
                        id: 'traffic-areas',
                        title: 'Traffic Areas',
                        translate: 'NAV.TRAFFIC_AREA.TITLE',
                        type: 'item',
                        url: '/setup/traffic-areas',
                        faIcon: faMapPin
                    },
                    {
                        id: 'control-areas',
                        title: 'Control Areas',
                        translate: 'NAV.CONTROL_AREA.TITLE',
                        type: 'item',
                        url: '/setup/control-areas',
                        faIcon: faMapPin
                    },
                    {
                        id: 'expense-type',
                        title: 'Expense Types',
                        translate: 'NAV.EXPENSE_TYPE.TITLE',
                        type: 'item',
                        url: '/setup/expense-types',
                        faIcon: faMoneyCheckAlt
                    },
                    {
                        id: 'expense-payment-method',
                        title: 'Expense Payment Method',
                        translate: 'NAV.EXPENSE_PAYMENT_METHOD.TITLE',
                        type: 'item',
                        url: '/setup/expense-payment-method',
                        faIcon: faMoneyCheckAlt
                    },
                    {
                        id: 'late-reasons',
                        title: 'Late Reasons',
                        translate: 'NAV.LATE_REASON.TITLE',
                        type: 'item',
                        url: '/setup/late-reasons',
                        faIcon: faHourglassHalf
                    },
                    {
                        id: 'deviation-reasons',
                        title: 'Deviation Reasons',
                        translate: 'NAV.DEVIATION_REASON.TITLE',
                        type: 'item',
                        url: '/setup/deviation-reasons',
                        faIcon: faHourglassHalf
                    },
                    {
                        id: 'claused-delivery-reasons',
                        title: 'Claused Delivery Reasons',
                        translate: 'NAV.CLAUSED_DELIVERY_REASON.TITLE',
                        type: 'item',
                        url: '/setup/claused-delivery-reasons',
                        faIcon: faAsterisk
                    },
                    {
                        id: 'accounts-package',
                        title: 'Accounts Package Setup',
                        translate: 'NAV.ACCOUNTS_PACKAGE.TITLE',
                        type: 'item',
                        faIcon: faTasks,
                        url: '/setup/accounts-package'
                    }
                ]
            }
        ]
    },
    {
        id: 'admin',
        title: 'ADMIN',
        translate: 'NAV.ADMIN',
        type: 'group',
        children: [
            {
                id: 'import-data',
                title: 'Import Data',
                translate: 'NAV.IMPORT_DATA.TITLE',
                type: 'item',
                url: '/setup/import-data',
                faIcon: faFileUpload
            },
            {
                id: 'import-orders',
                title: 'Import Orders',
                translate: 'NAV.IMPORT_ORDER.TITLE',
                type: 'item',
                url: '/setup/import-orders',
                faIcon: faFileUpload
            },
            {
                id: 'location-point-awaiting-integration',
                title: 'Points for Integration',
                translate: 'NAV.LOCATION_POINT_AWAITING_INTEGRATION.TITLE',
                type: 'item',
                url: '/setup/location-points-awaiting-integration',
                faIcon: faMapPin
            },
            {
                id: 'haulier-order-awaiting-integration',
                title: 'Orders Awaiting Integration',
                translate: 'NAV.HAULIER_ORDER_AWAITING_INTEGRATION.TITLE',
                type: 'item',
                url: '/orders/haulier-order-awaiting-integration',
                faIcon: faPallet
            },
            {
                id: 'metsa-order',
                title: 'Metsa Order Screen',
                translate: 'NAV.METSA_ORDER.TITLE',
                type: 'item',
                url: '/orders/metsa-order',
                faIcon: faPallet
            }
        ]
    }
];

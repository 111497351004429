import {FilterType} from 'app/blocks/util/filter-type';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {SiteVisitStatus} from 'app/blocks/model/site-visit-status.model';
import {ITrafficArea, TrafficArea} from 'app/blocks/model/traffic-area.model';
import {INote, Note} from 'app/blocks/model/note.model';
import {IOrganisationContactInfo} from 'app/blocks/model/organisation-contact-info.model';
import {ActionType} from '@ngxs/store';
import {IAddress} from 'app/blocks/model/address.model';
import {IDriver} from 'app/blocks/model/driver.model';
import {ILegRoute} from 'app/blocks/model/leg-route.model';
import {IResourceIcon} from 'app/blocks/model/resource-icon.model';
import {ICurrency} from 'app/blocks/model/currency.model';

export interface ITrafficSheetRun extends IBaseEntity {
    runId: number;
    legs: TrafficSheetLeg[];
    treeList: boolean;
}

export class TrafficSheetRun extends BaseEntity implements ITrafficSheetRun {
    public runId: number;
    public legs: Array<TrafficSheetLeg>;
    public treeList: boolean;

    constructor(tsRun?: any) {
        super();
        if (tsRun) {
            this.runId = tsRun.runId;
            this.legs = tsRun.legs;
            this.treeList = tsRun.treeList;
        }
    }

    get discriminator(): any {
        return this.runId;
    }
    clone(): TrafficSheetRun {
        return new TrafficSheetRun(this);
    }
}

export interface ITrafficSheetLeg extends IBaseEntity {
    id?: number;
    plannedStartTime?: any;
    plannedEndTime?: any;
    legStatus: string;
    sequence: number;
    resourced: boolean;
    communicated: boolean;
    completed: boolean;
    fromStop: ITrafficSheetStop;
    toStop: ITrafficSheetStop;
    fromStopStatus: SiteVisitStatus;
    toStopStatus: SiteVisitStatus;
    driverId?: number;
    vehicleId?: number;
    trailerId?: number;
    driver?: IDriver;
    vehicleName?: string;
    trailerName?: string;
    customerTrailerRef?: string;
    subContractorId?: number;
    orders: ITrafficSheetOrder[];
    warnings?: any[];
    runId?: number;
    subContractorName?: string;
    subContractorRate?: number;
    legRoute: ILegRoute;
    items: number;
    weight: number;
}

export class TrafficSheetLeg extends BaseEntity implements ITrafficSheetLeg {
    public id: number;
    public plannedStartTime?: any;
    public plannedEndTime?: any;
    public legStatus: string;
    public sequence: number;
    public resourced: boolean;
    public communicated: boolean;
    public completed: boolean;
    public fromStop: ITrafficSheetStop;
    public toStop: ITrafficSheetStop;
    public fromStopStatus: SiteVisitStatus;
    public toStopStatus: SiteVisitStatus;
    public driverId?: number;
    public vehicleId?: number;
    public trailerId?: number;
    public driver?: IDriver;
    public vehicleName?: string;
    public trailerName?: string;
    public customerTrailerRef?: string;
    public subContractorId?: number;
    public orders: ITrafficSheetOrder[];
    public subContractorName: string;
    public subContractorRate: number;
    public runId?: number;
    public hasWarnings?: boolean;
    public warnings?: any[];
    public legRoute: ILegRoute;
    public index?: number;
    public items: number;
    public weight: number;
    public liveETA?: Date;

    constructor(legTrafficSheet?: any) {
        super();
        if (legTrafficSheet) {
            this.id = legTrafficSheet.id;
            this.plannedStartTime = legTrafficSheet.plannedStartTime;
            this.plannedEndTime = legTrafficSheet.plannedEndTime;
            this.legStatus = legTrafficSheet.legStatus;
            this.sequence = legTrafficSheet.sequence;
            this.resourced = legTrafficSheet.resourced;
            this.communicated = legTrafficSheet.communicated;
            this.completed = legTrafficSheet.completed;
            this.fromStop = legTrafficSheet.fromStop;
            this.toStop = legTrafficSheet.toStop;
            this.toStopStatus = legTrafficSheet.toStopStatus;
            this.fromStopStatus = legTrafficSheet.fromStopStatus;
            this.driverId = legTrafficSheet.driverId;
            this.vehicleId = legTrafficSheet.vehicleId;
            this.trailerId = legTrafficSheet.trailerId;
            this.driver = legTrafficSheet.driver;
            this.vehicleName = legTrafficSheet.vehicleName;
            this.trailerName = legTrafficSheet.trailerName;
            this.subContractorId = legTrafficSheet.subContractorId;
            this.orders = legTrafficSheet.orders;
            this.runId = legTrafficSheet.runId;
            this.customerTrailerRef = legTrafficSheet.customerTrailerRef;
            this.hasWarnings = legTrafficSheet.hasWarnings;
            this.subContractorName = legTrafficSheet.subContractorName;
            this.subContractorRate = legTrafficSheet.subContractorRate;
            this.legRoute = legTrafficSheet.legRoute;
            this.items = legTrafficSheet.items;
            this.weight = legTrafficSheet.weight;
            this.warnings = legTrafficSheet.warnings || [];
            this.liveETA = legTrafficSheet.liveETA;
        }
    }
    get discriminator(): any {
        return this.id;
    }

    get flags() {
        return this.orders?.flatMap((o) => o.resourceIcons) || [];
    }

    clone(): TrafficSheetLeg {
        return new TrafficSheetLeg(this);
    }
}

export interface ITrafficSheetStop extends IBaseEntity {
    id?: number;
    arrivalTime: any;
    departureTime: any;
    gpsArriveTime?: any;
    gpsDepartTime?: any;
    gpsTimezoneOffsetInMs?: number;
    point?: ITrafficSheetPoint;
    actionType: string;
}

export class TrafficSheetStop extends BaseEntity implements ITrafficSheetStop {
    public id?: number;
    public arrivalTime: any;
    public departureTime: any;
    public gpsArriveTime?: any;
    public gpsDepartTime?: any;
    public gpsTimezoneOffsetInMs?: number;
    public point?: ITrafficSheetPoint;
    public actionType: string;

    constructor(stop?: any) {
        super();
        if (stop) {
            this.id = stop.id;
            this.arrivalTime = stop.arrivalTime;
            this.departureTime = stop.departureTime;
            this.gpsArriveTime = stop.gpsArriveTime;
            this.gpsDepartTime = stop.gpsDepartTime;
            this.gpsTimezoneOffsetInMs = stop.gpsTimezoneOffsetInMs;
            this.point = stop.point;
            this.actionType = stop.actionType;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): TrafficSheetStop {
        return new TrafficSheetStop(this);
    }
}

export interface ITrafficSheetPoint extends IBaseEntity {
    id?: number;
    description?: string;
    contactName?: string;
    contactPhone?: string;
    contactEmail?: string;
    trafficArea?: ITrafficArea;
    address?: IAddress;
}

export class TrafficSheetPoint extends BaseEntity implements ITrafficSheetPoint {
    public id?: number;
    public description?: string;
    public contactName?: string;
    public contactPhone?: string;
    public contactEmail?: string;
    public trafficArea?: ITrafficArea;
    public address?: IAddress;

    constructor(point?) {
        super();
        if (point) {
            this.id = point.id;
            this.description = point.description;
            this.contactName = point.contactName;
            this.contactPhone = point.contactPhone;
            this.contactEmail = point.contactEmail;
            this.trafficArea = point.trafficArea;
            this.address = point.address;
        } else {
            this.trafficArea = new TrafficArea();
        }
    }

    get discriminator(): any {
        return this.description;
    }

    clone(): TrafficSheetPoint {
        return new TrafficSheetPoint(this);
    }
}

export interface ITrafficSheetOrder extends IBaseEntity {
    id?: number;
    orderNo?: string;
    loadNumber?: string;
    instructions?: INote;
    trafficNotes?: INote;
    customer?: TrafficSheetOrganisation;
    customerId?: number;
    collectionWindow?: any;
    rate?: number;
    serviceLevel?: string;
    resourceIcons: IResourceIcon[];
}

export class TrafficSheetOrder extends BaseEntity implements ITrafficSheetOrder {
    public id?: number;
    public orderNo?: string;
    public loadNumber?: string;
    public instructions?: INote;
    public trafficNotes?: INote;
    public customer?: TrafficSheetOrganisation;
    public customerId?: number;
    public collectionWindow?: any;
    public rate?: number;
    public serviceLevel?: string;
    resourceIcons: IResourceIcon[];

    constructor(tsOrder?) {
        super();
        if (tsOrder) {
            this.id = tsOrder.id;
            this.orderNo = tsOrder.orderNo;
            this.loadNumber = tsOrder.loadNumber;
            this.instructions = tsOrder.instructions ? tsOrder.instructions : new Note();
            this.trafficNotes = tsOrder.trafficNotes ? tsOrder.trafficNotes : new Note();
            this.customer = tsOrder.customer;
            this.customerId = tsOrder.customerId;
            this.collectionWindow = tsOrder.collectionTime;
            this.rate = tsOrder.rate;
            this.serviceLevel = tsOrder.serviceLevel;
            this.resourceIcons = tsOrder.resourceIcons;
        } else {
            this.orderNo = '';
            this.instructions = new Note();
            this.trafficNotes = new Note();
            this.customer = new TrafficSheetOrganisation();
            this.customerId = 0;
            this.resourceIcons = [];
        }
    }
    get discriminator(): any {
        return this.orderNo;
    }

    clone(): ITrafficSheetOrder {
        return new TrafficSheetOrder(this);
    }
}

export interface ITrafficSheetOrganisation extends IBaseEntity {
    id?: number;
    name?: string;
    contacts?: IOrganisationContactInfo[];
    currency?: ICurrency;
}

export class TrafficSheetOrganisation extends BaseEntity implements ITrafficSheetOrganisation {
    public id?: number;
    public name?: string;
    public contacts?: IOrganisationContactInfo[];
    public currency?: ICurrency;

    constructor(organisation?: any) {
        super();
        if (organisation) {
            this.id = organisation.id;
            this.name = organisation.name;
            this.contacts = organisation.contacts;
            this.currency = organisation.currency;
        } else {
            this.contacts = [];
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): TrafficSheetOrganisation {
        return new TrafficSheetOrganisation(this);
    }
}

export interface IDraggableColumns {
    id?: number;
    name: string;
    width?: string;
    sort: boolean;
    data?: any[];
    draggable?: boolean;
    position?: number;
    display?: boolean;
    filter?: string;
    filterType?: FilterType;
    filterFunc?: any;
    displayAttribute?: string;
}

export class DraggableColumn implements IDraggableColumns {
    public id?: number;
    public name: string;
    public width?: string;
    public sort: boolean;
    public data?: any[];
    public draggable?: boolean;
    public position?: number;
    public display?: boolean;
    public filter?: string;
    public filterType?: FilterType;
    public filterFunc?: any;
    public displayAttribute?: string;
    public displayFn?: any;

    constructor(draggableColumn?: any) {
        this.id = draggableColumn.id;
        this.name = draggableColumn.name;
        this.width = draggableColumn.width;
        this.data = draggableColumn.data;
        this.sort = draggableColumn.sort;
        this.draggable = draggableColumn.draggable;
        this.position = draggableColumn.position;
        this.display = draggableColumn.display;
        this.filter = draggableColumn.filter;
        this.filterType = draggableColumn.filterType;
        this.filterFunc = draggableColumn.filterFunc;
        this.displayAttribute = draggableColumn.displayAttribute;
    }
}

export interface IDisplaySetting {
    id?: number;
    attributeName?: string;
    display: boolean;
    draggable?: boolean;
    position?: number;
}

export class DisplaySetting implements IDisplaySetting {
    public id?: number;
    public attributeName?: string;
    public display: boolean;
    public draggable?: boolean;
    public position?: number;

    constructor(displaySetting) {
        this.id = displaySetting.id;
        this.attributeName = displaySetting.attributeName;
        this.display = displaySetting.display;
        this.draggable = displaySetting.draggable;
        this.position = displaySetting.position;
    }
}

export interface IConfigurableColumn {
    column: boolean;
}

export class ConfigurableColumn implements IConfigurableColumn {
    column: boolean;

    constructor(configurableColumn) {
        this.column = configurableColumn.columns;
    }
}

type LegAction = 'resourced' | 'communicated' | 'completed' | 'enedIn' | 'unResourced' | 'unCommunicated' | 'subContracted' | 'unsubContracted';

export interface ILegChangeSubject {
    legIds: number[];
    action: LegAction;
}
export class LegChangeSubject implements ILegChangeSubject {
    public legIds: number[];
    public action;

    constructor(legChangeSubject) {
        this.legIds = legChangeSubject.legIds;
        this.action = legChangeSubject.action;
    }
}

export interface IModalFilter {
    primaryFilter: string;
    dateFrom?: Date;
    dateTo?: Date;
    dateToFilterOnFilter?: string;
    dateToStopFilter?: string;
    status?: any[];
    legStatus?: any[];
    resources?: string;
    primarySearchString: string;
    resourceSearchString: string;
    trafficAreas: any[];
    businessTypes: any[];
    controlArea: any;
}
export class ModalFilter implements IModalFilter {
    public primaryFilter: string;
    public trafficAreas: any[];
    public businessTypes: any[];
    public dateFrom?: Date;
    public dateTo?: Date;
    public dateToFilterOnFilter?: string;
    public dateToStopFilter?: string;
    public status?: any[];
    public legStatus?: any[];
    public resources?: string;
    public primarySearchString: string;
    public resourceSearchString: string;
    public controlArea: any;
    public resourceDepotSearchString: string;
    public fromStop: string;
    public toStop: string;

    constructor(modalFilter) {
        this.primaryFilter = modalFilter.primaryFilter;
        this.trafficAreas = modalFilter.trafficAreas;
        this.businessTypes = modalFilter.businessTypes;
        this.dateFrom = modalFilter.dateFrom;
        this.dateTo = modalFilter.dateTo;
        this.dateToFilterOnFilter = modalFilter.dateToFilterOnFilter;
        this.dateToStopFilter = modalFilter.dateToStopFilter;
        this.status = modalFilter.status;
        this.legStatus = modalFilter.legStatus;
        this.resources = modalFilter.resources;
        this.primarySearchString = modalFilter.primarySearchString;
        this.resourceSearchString = modalFilter.resourceSearchString;
        this.controlArea = modalFilter.controlArea;
        this.resourceDepotSearchString = modalFilter.resourceDepotSearchString;
        this.fromStop = modalFilter.fromStop;
        this.toStop = modalFilter.toStop;
    }
}

export interface IResourceItem {
    id?: number;
    driverId?: number;
    vehicleId?: number;
    trailerId?: number;
    status?: string;
    customerTrailerRef?: string;
    legId?: number;
    trafficAreaId?: number;
    controlAreaId?: number;
}

export class ResourceItem implements IResourceItem {
    public id: number;
    public driverId: number;
    public vehicleId: number;
    public trailerId: number;
    public shiftTypeId: number;
    public status?: string;
    public customerTrailerRef?: string;
    public communicateResource: boolean;
    public trafficAreaId?: number;
    public controlAreaId?: number;
    public tracking: boolean;
    constructor(resourceItem?) {
        this.id = resourceItem.id;
        this.driverId = resourceItem.driverId;
        this.vehicleId = resourceItem.vehicleId;
        this.trailerId = resourceItem.trailerId;
        this.shiftTypeId = resourceItem.shiftTypeId;
        this.status = resourceItem.status;
        this.customerTrailerRef = resourceItem.customerTrailerRef;
        this.communicateResource = resourceItem.communicateResource;
        this.trafficAreaId = resourceItem.trafficAreaId;
        this.controlAreaId = resourceItem.controlAreaId;
        this.tracking = resourceItem.tracking;
    }
}

export interface ISubContractItem {
    legId?: number;
    subContractorId?: number;
}

export class SubContractItem implements ISubContractItem {
    public legId: number;
    public subContractorId?: number;
}

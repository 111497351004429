import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IEventType extends IBaseEntity {
    id?: number;
    name: string;
}

export class EventType extends BaseEntity implements IEventType {
    public id?: number;
    public name: string;

    constructor(eventType?: any) {
        super();
        if (eventType) {
            this.id = eventType.id;
            this.name = eventType.name;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): EventType {
        return new EventType(this);
    }
}

import {IOrganisationExtraType} from 'app/blocks/model/organisation-extra-type.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {ILocationPoint} from 'app/blocks/model/location-point.model';
import {IOrganisation, Organisation} from 'app/blocks/model/organisation.model';
import {NonUserContact} from 'app/blocks/model/non-user-contact.model';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Observable} from 'rxjs';
import {IHmTemplate} from 'app/blocks/model/template.model';
import {IFuelSurchargeGroup} from 'app/blocks/model/fuel-surcharge-group.model';
import {PriceList} from 'app/blocks/model/price-list.model';
import {IExtraType} from 'app/blocks/model/extra-type.model';
import {map} from 'rxjs/operators';
import {CustomerPortalUser} from 'app/blocks/model/organisation-user.model';
import {FileType} from 'app/blocks/model/hm-file.model';
import {WebApiKeyRequest} from 'app/blocks/model/hm-api-key.model';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService extends EntityCrudService<IOrganisation> {
    constructor(http: HttpClient) {
        super(http, Resource.ORGANISATIONS, (obj?) => new Organisation(obj));
    }

    getLocationPointsForOrganisation = (id: any): Promise<any[]> => {
        return this.http.get<ILocationPoint[]>(`${SERVER_API_URL}api/organisations/${id}/location-points`).toPromise();
    };

    getContactEmailForOrganisation = (id: any): Promise<any[]> => {
        return this.http
            .get<any>(`${SERVER_API_URL}api/organisations/${id}/primary-contact`)
            .toPromise()
            .then((pair) => {
                if (pair == null) {
                    return pair;
                }
                return [pair];
            });
    };

    getFuelSurchargeGroupByOrganisation = (id: any): Promise<any> => {
        return this.http.get<IFuelSurchargeGroup>(`${SERVER_API_URL}api/organisations/${id}/fuel-surcharge-group`).toPromise();
    };

    copyOrganisation = (organisationId: any): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/organisations/${organisationId}/copy`).toPromise();
    };

    getExtraOptionsOrganisation = (id: any): Promise<any[]> => {
        return this.http.get<IOrganisationExtraType[]>(`${SERVER_API_URL}api/organisation-extra-types/${id}/organisation-extras`).toPromise();
    };

    getSystemWideExtraTypes = (): Promise<any[]> => {
        return this.http.get<IExtraType[]>(`${SERVER_API_URL}api/extra-types-system`).toPromise();
    };

    getSettingMetaDataForOrganisation = (isTenant?): Promise<any[]> => {
        let requestParams = new HttpParams();
        if (isTenant) {
            requestParams = requestParams.set('isTenant', isTenant);
        }

        return this.http
            .get<any>(`${SERVER_API_URL}api/organisations/settings`, {
                params: requestParams
            })
            .toPromise();
    };

    getMobileSettingMetaDataForOrganisation = (): Promise<any[]> => {
        return this.http.get<any>(`${SERVER_API_URL}api/organisations/mobile-settings`).toPromise();
    };

    getTemplates = (): Observable<IHmTemplate[]> => {
        return this.http.get<any>(`${SERVER_API_URL}api/organisations/templates`);
    };

    inviteOrganisationsToClientPortal = (nonUserContacts: CustomerPortalUser[], organisationId: number): Promise<any> => {
        return this.http.post<any>(`${SERVER_API_URL}api/organisations/${organisationId}/invite-to-client-portal`, nonUserContacts).toPromise();
    };

    removeUserFromOrganisation = (contactId: number, organisationId: number): Promise<any> => {
        return this.http.delete<any>(`${SERVER_API_URL}api/organisations/${organisationId}/user-accounts/${contactId}`).toPromise();
    };

    getUserAccounts = (id: number): Promise<CustomerPortalUser[]> => {
        return this.http.get<any>(`${SERVER_API_URL}api/organisations/${id}/user-accounts`).toPromise();
    };

    getNonAssignedCustomer(orgId: number, query?: string): Observable<CustomerPortalUser[]> {
        return this.http.get<any[]>(`${SERVER_API_URL}api/organisations/${orgId}/non-assigned-customers`, {
            params: {
                query
            }
        });
    }

    getOrganisation = (id: any): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/organisations/${id}`).toPromise();
    };

    addApiKeyForOrganisation = (id: any, apiKey: WebApiKeyRequest): Promise<void> => {
        return this.http.post<any>(`${SERVER_API_URL}api/organisations/${id}/web-api-key`, apiKey).toPromise();
    };

    getApiKeyForOrganisationApi = (id: any): Promise<WebApiKeyRequest> => {
        return this.http.get<WebApiKeyRequest>(`${SERVER_API_URL}api/organisations/${id}/web-api-key`).toPromise();
    };

    deleteApiKeyForOrganisationApi = (id: any): Promise<void> => {
        return this.http.delete<any>(`${SERVER_API_URL}api/organisations/${id}/web-api-key`).toPromise();
    };

    getTenantOrganisation = (): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/organisations/tenant`).toPromise();
    };

    authoriseForXero = (): Promise<any> => {
        return this.http
            .get(`${SERVER_API_URL}api/accounts-package/authorise`, {
                responseType: 'text'
            })
            .toPromise();
    };

    disconnectFromXero = (): Promise<any> => {
        return this.http.get(`${SERVER_API_URL}api/accounts-package/disconnect`).toPromise();
    };

    getAccountsPackage = (): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/organisations/accounts-package`).toPromise();
    };

    saveAccountsPackage = (accountsPackage: any): Promise<any> => {
        return this.http.post(`${SERVER_API_URL}api/organisations/accounts-package`, accountsPackage, {responseType: 'text'}).toPromise();
    };

    uploadLogo = (id: any, selectedFile: any, width?: any, height?: any): Promise<any> => {
        const fd = new FormData();
        fd.append('file', selectedFile, selectedFile.name);
        fd.append('width', '' + width);
        fd.append('height', '' + height);
        return this.http.post<any>(SERVER_API_URL + `api/organisations/${id}/logo`, fd).toPromise();
    };

    getLogo(id: any): Promise<any> {
        const REQUEST_URI = SERVER_API_URL + `api/organisations/${id}/logo`;
        return this.http.get(REQUEST_URI, {responseType: 'blob'}).toPromise();
    }

    getPriceLists = (organisationId): Promise<PriceList[]> => {
        return this.http.get<PriceList[]>(`${SERVER_API_URL}api/organisations/${organisationId}/price-lists `).toPromise();
    };

    getOrganisationDefaults = (organisationId): Promise<any> => {
        return this.http.get(`${SERVER_API_URL}api/organisations/${organisationId}/defaults`).toPromise();
    };

    getTenantDefaults = (): Promise<any> => {
        return this.http.get(`${SERVER_API_URL}api/organisations/defaults`).toPromise();
    };

    getActiveGoodTypes(id: any): Observable<any[]> {
        const REQUEST_URI = SERVER_API_URL + `api/organisations/${id}/good-types`;
        return this.http.get<any>(REQUEST_URI);
    }

    getActiveServiceLevels(id: any): Observable<any[]> {
        const REQUEST_URI = SERVER_API_URL + `api/organisations/${id}/service-levels`;
        return this.http.get<any>(REQUEST_URI);
    }

    getActiveBusinessTypes(id: any): Promise<any> {
        const REQUEST_URI = SERVER_API_URL + `api/organisations/${id}/business-types`;
        return this.http.get<any>(REQUEST_URI).toPromise();
    }

    getOrganisationsForControlArea = (controlAreaId: number): Promise<any> => {
        return this.http
            .get(`${SERVER_API_URL}api/organisations/control-area/${controlAreaId}`, {
                observe: 'response'
            })
            .toPromise();
    };

    getOrganisationByName = (name: string): Observable<IOrganisation> => {
        return this.http
            .get<IOrganisation[]>(`${SERVER_API_URL}api/_search/organisations/`, {params: {query: name, size: 1, page: 0}})
            .pipe(map((orgs) => orgs.find((org) => org.name === name)));
    };

    getFuelSurchargesForOrganisation = (id: number): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/organisations/${id}/fuel-surcharges`).toPromise();
    };

    getSettingvalueForCustomer = (id: number, key: string): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/customer-organisation-setting/${id}/${key}`).toPromise();
    };

    uploadFile = (label: string, file: File, fileType: string): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('label', label);
        fd.append('fileType', fileType);
        return this.http.post<any>(`${SERVER_API_URL}api/organisations/docs`, fd).toPromise();
    };
}

import {FuseNavigation} from '@fuse/types';
import {
    faTruckMoving,
    faTruckPickup,
    faFileUpload,
    faPallet,
    faHardHat,
    faWrench,
    faFileContract,
    faIdBadge,
    faShippingFast,
    faTruck,
    faExchangeAlt,
    faTasks,
    faBoxOpen,
    faPercent,
    faBuilding,
    faMapPin,
    faMoneyCheckAlt,
    faTrailer,
    faCaravan,
    faBell,
    faGlasses,
    faStar,
    faUsers,
    faHourglassHalf,
    faAsterisk,
    faMoneyBill,
    faLocationArrow,
    faTruckLoading,
    faBookOpen,
    faClock,
    faCalendarDay,
    faCalendar,
    faCalendarPlus,
    faTrafficLight,
    faMoneyCheck,
    faShapes,
    faFolderPlus,
    faFlag,
    faFolderOpen,
    faTruckMonster,
    faUndo,
    faMoneyBillWave,
    faFileInvoiceDollar,
    faBatteryEmpty,
    faMapMarked,
    faPlus,
    faAddressCard,
    faBan,
    faCoffee,
    faFilePdf
} from '@fortawesome/free-solid-svg-icons';

export const adminNavigation: FuseNavigation[] = [
    {
        id: 'planning',
        title: 'Planning',
        translate: 'NAV.PLANNING',
        type: 'group',
        icon: 'receipt',
        children: [
            {
                id: 'orders',
                title: 'Orders',
                translate: 'NAV.ORDERS.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'haulier-order-all',
                        title: 'Orders',
                        translate: 'NAV.HAULIER_ORDER_ALL.TITLE',
                        type: 'item',
                        url: '/orders/haulier-orders',
                        faIcon: faPallet
                    },
                    {
                        id: 'haulier-order-create',
                        title: 'Create Order(s)',
                        translate: 'NAV.HAULIER_ORDER_CREATE.TITLE',
                        type: 'item',
                        url: '/orders/haulier-orders/new',
                        faIcon: faPlus,
                        openInNewTab: true
                    },
                    {
                        id: 'haulier-order-pending-approval',
                        title: 'Pending Orders',
                        translate: 'NAV.HAULIER_ORDER_PENDING_APPROVAL.TITLE',
                        type: 'item',
                        url: '/orders/haulier-order-pending-approval',
                        faIcon: faClock
                    },
                    {
                        id: 'haulier-order-collections',
                        title: 'Collections',
                        translate: 'NAV.HAULIER_ORDER_COLLECTIONS.TITLE',
                        type: 'item',
                        url: '/orders/haulier-order-collections',
                        faIcon: faTruckLoading
                    },
                    {
                        id: 'haulier-order-deliveries',
                        title: 'Deliveries',
                        translate: 'NAV.HAULIER_ORDER_DELIVERIES.TITLE',
                        type: 'item',
                        url: '/orders/haulier-order-deliveries',
                        faIcon: faTruckLoading
                    },
                    {
                        id: 'map-based-planning',
                        title: 'Map-Based Planning',
                        translate: 'NAV.MAP_BASED_PLANNING.TITLE',
                        type: 'item',
                        url: '/orders/map-based-planning',
                        faIcon: faMapMarked
                    }
                ]
            },
            {
                id: 'run',
                title: 'Run List',
                translate: 'NAV.RUN.TITLE',
                type: 'item',
                url: '/runs/runs',
                faIcon: faShippingFast
            },
            {
                id: 'shift-planner',
                title: 'Shift Planner',
                translate: 'NAV.SHIFT_PLANNING.TITLE',
                type: 'item',
                url: '/resource-planning/shift-planning',
                faIcon: faCalendarDay
            },
            {
                id: 'resource-scheduler',
                title: 'Resource Scheduler',
                translate: 'NAV.SCHEDULING',
                type: 'item',
                url: '/resource-planning/resource-planning',
                faIcon: faCalendar
            },
            {
                id: 'order-scheduler',
                title: 'Run Planner',
                translate: 'NAV.ORDER_SCHEDULING',
                type: 'item',
                url: '/resource-planning/order-scheduling',
                faIcon: faCalendarPlus
            },
            {
                id: 'traffic-sheet',
                title: 'Traffic Sheet',
                translate: 'NAV.TRAFFIC_SHEET.TITLE',
                type: 'item',
                url: '/traffic-sheets/traffic-sheet',
                faIcon: faTrafficLight
            },
            {
                id: 'new-traffic-sheet',
                title: 'New Traffic Sheet (Beta)',
                translate: 'NAV.NEW_TRAFFIC_SHEET.TITLE',
                type: 'item',
                url: '/new-traffic-sheet',
                faIcon: faTrafficLight
            },
            {
                id: 'leg-planner',
                title: 'Leg Planner',
                translate: 'NAV.LEG_PLANNER.TITLE',
                type: 'item',
                url: '/leg-planning/leg-planning',
                faIcon: faCalendarDay
            },

            // {
            //     id: 'tour-planner',
            //     title: 'Tour Planners',
            //     translate: 'NAV.TOUR_PLANNER.TITLE',
            //     type: 'item',
            //     url: '/tour-planners/tour-planners',
            //     faIcon: faStar,
            // },

            {
                id: 'manifest',
                title: 'Created Manifest',
                translate: 'NAV.MANIFEST.TITLE',
                type: 'item',
                url: '/manifests/manifests',
                faIcon: faStar
            },
            {
                id: 'create-manifest',
                title: 'Manifest',
                translate: 'NAV.CREATE_MANIFEST.TITLE',
                type: 'item',
                url: '/setup/create-manifest',
                faIcon: faStar
            },
            {
                id: 'loading-sheet',
                title: 'Loading Sheet',
                translate: 'NAV.LOADING_SHEET.TITLE',
                type: 'item',
                url: '/loading-sheet',
                faIcon: faFilePdf
            },
            {
                id: 'expenses',
                title: 'Driver Expenses',
                translate: 'NAV.EXPENSES.TITLE',
                type: 'item',
                url: '/expense/expenses',
                faIcon: faMoneyCheck
            }
        ]
    },
    {
        id: 'Invoicing',
        title: 'Invoicing',
        translate: 'NAV.INVOICING',
        type: 'group',
        faIcon: faFileInvoiceDollar,
        children: [
            {
                id: 'mark-ready-to-invoice',
                title: 'Flag Orders as Ready To Invoice',
                translate: 'NAV.MARK_READY_TO_INVOICE.TITLE',
                type: 'item',
                url: '/orders/haulier-order-mark-invoice',
                faIcon: faFlag
            },
            {
                id: 'ready-to-invoice',
                title: 'Create Pre-Invoice Batch',
                translate: 'NAV.READY_TO_INVOICE.TITLE',
                type: 'item',
                url: '/orders/haulier-order-ready-to-invoice',
                faIcon: faFolderPlus
            },
            {
                id: 'pre-invoice-batch',
                title: 'Pre-Invoice Batches',
                translate: 'NAV.PRE_INVOICE_BATCH.TITLE',
                type: 'item',
                url: '/setup/pre-invoice-batches',
                faIcon: faFolderOpen
            },
            {
                id: 'multi-batch-invoice',
                title: 'Multi-Batch Invoices',
                translate: 'NAV.MULTI_BATCH_INVOICE.TITLE',
                type: 'item',
                url: '/setup/multi-batch-invoices',
                faIcon: faFolderPlus
            },
            {
                id: 'invoice',
                title: 'Invoices',
                translate: 'NAV.INVOICE.TITLE',
                type: 'item',
                url: '/setup/invoices',
                faIcon: faFileInvoiceDollar
            },
            {
                id: 'payments',
                title: 'Payments',
                translate: 'NAV.PAYMENTS.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/payments',
                faIcon: faMoneyBillWave
            },
            {
                id: 'credit-note',
                title: 'Credit Notes',
                translate: 'NAV.CREDIT_NOTE.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/credit-notes'
            },
            {
                id: 'extraInvoice',
                title: 'Extra Invoice',
                translate: 'NAV.EXTRA_INVOICE.TITLE',
                type: 'item',
                url: '/setup/extra-invoice',
                faIcon: faFileInvoiceDollar
            },
            {
                id: 'subcontractorInvoice',
                title: 'Subcontractor Invoice',
                translate: 'NAV.SUBCONTRACTOR_INVOICE.TITLE',
                type: 'item',
                url: '/setup/subcontractor-invoice',
                faIcon: faFileInvoiceDollar
            }
        ]
    },
    {
        id: 'tracking',
        title: 'Tracking',
        translate: 'NAV.TRACKING',
        type: 'group',
        faIcon: faLocationArrow,
        children: [
            {
                id: 'fleetview',
                title: 'Fleet View',
                translate: 'NAV.FLEETVIEW.TITLE',
                type: 'item',
                url: '/fleetviews',
                faIcon: faLocationArrow
            },
            {
                id: 'dc-dashboard',
                title: 'DC Dashboard',
                translate: 'NAV.DC_DASHBOARD.TITLE',
                type: 'item',
                url: '/setup/dc-dashboard',
                faIcon: faStar
            },
            {
                id: 'etaArrivalsBoard',
                title: 'ETA Arrivals Board',
                translate: 'NAV.ETA_ARRIVALS_BOARD.TITLE',
                type: 'item',
                url: '/history/eta-arrivals-board',
                faIcon: faShippingFast
            }
        ]
    },
    {
        id: 'Reporting',
        title: 'Reporting',
        translate: 'NAV.REPORTING',
        type: 'group',
        faIcon: faFileContract,
        children: [
            {
                id: 'garage',
                title: 'Garage',
                translate: 'NAV.GARAGE.TITLE',
                type: 'item',
                faIcon: faTruckMonster,
                url: '/setup/garage'
            },
            {
                id: 'package-returns',
                title: 'Package Returns',
                translate: 'NAV.PACKAGE_RETURNS.TITLE',
                type: 'item',
                faIcon: faUndo,
                url: '/setup/package-returns'
            },
            {
                id: 'attempted-delivery',
                title: 'Outstanding Goods Refusal',
                translate: 'NAV.ATTEMPTED_DELIVERY.TITLE',
                type: 'item',
                faIcon: faFileContract,
                url: '/setup/attempted-delivery'
            },
            {
                id: 'core-reports',
                title: 'Core Reports',
                translate: 'NAV.CORE_REPORTS.TITLE',
                type: 'item',
                faIcon: faFileContract,
                url: '/analytics/reports'
            },
            {
                id: 'vehicleInspections',
                title: 'Vehicle Inspections',
                translate: 'NAV.VEHICLE_INSPECTIONS.TITLE',
                type: 'item',
                faIcon: faWrench,
                url: '/history/vehicle-inspections'
            },
            {
                id: 'pods',
                title: 'Proof of Delivery',
                translate: 'NAV.PODS.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/history/pods'
            },
            {
                id: 'outstanding-pods',
                title: 'Outstanding Pods',
                translate: 'NAV.OUSTANDING_PODS.TITLE',
                type: 'item',
                url: '/history/outstanding-pod-report',
                icon: 'bar_chart'
            },
            {
                id: 'outstanding-call-ins',
                title: 'Outstanding Call-Ins',
                translate: 'NAV.OUTSTANDING_CALL_INS.TITLE',
                type: 'item',
                url: '/history/outstanding-call-in-report',
                icon: 'bar_chart'
            },
            {
                id: 'revenueReport',
                title: 'Revenue Report',
                translate: 'NAV.REVENUE_REPORT.TITLE',
                type: 'item',
                url: '/history/revenue-report',
                faIcon: faMoneyBill
            },
            {
                id: 'customerReports',
                title: 'Customer Reports',
                translate: 'NAV.CUSTOMER_REPORTS.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'completedRunReport',
                        title: "Completed Run Call In's",
                        translate: 'NAV.COMPLETED_RUN_REPORT.TITLE',
                        type: 'item',
                        url: '/history/completed-run-report',
                        faIcon: faFileContract
                    },
                    {
                        id: 'allRunsForClientsReport',
                        title: 'All Runs For Clients',
                        translate: 'NAV.ALL_RUNS_FOR_CLIENTS_REPORT.TITLE',
                        type: 'item',
                        url: '/history/all-runs-for-clients-report',
                        faIcon: faFileContract
                    },
                    {
                        id: 'clientWorkReport',
                        title: 'Client Work',
                        translate: 'NAV.CLIENT_WORK_REPORT.TITLE',
                        type: 'item',
                        url: '/history/client-work-report',
                        faIcon: faFileContract
                    },
                    {
                        id: 'clientRevenueReport',
                        title: 'Client Revenue',
                        translate: 'NAV.CLIENT_REVENUE_REPORT.TITLE',
                        type: 'item',
                        url: '/history/client-revenue-report',
                        faIcon: faFileContract
                    },
                    {
                        id: 'customer-transactions',
                        title: 'Customer Balance Ledger',
                        translate: 'NAV.CUSTOMER_TRANSACTIONS.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/customer-transactions'
                    }
                ]
            },
            {
                id: 'driverReports',
                title: 'Driver Reports',
                translate: 'NAV.DRIVER_REPORTS.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'driverStartDayLog',
                        title: 'Driver Start Day Log',
                        translate: 'NAV.DRIVER_START_DAY_LOG.TITLE',
                        type: 'item',
                        url: '/history/driver-start-day-log',
                        faIcon: faBookOpen
                    },
                    {
                        id: 'driverMessage',
                        title: 'Driver Messages',
                        translate: 'NAV.DRIVER_MESSAGE.TITLE',
                        type: 'item',
                        url: '/history/driver-messages',
                        faIcon: faBookOpen
                    },
                    {
                        id: 'driverWorkReport',
                        title: 'Driver Revenue Report',
                        translate: 'NAV.DRIVER_WORK_REPORT.TITLE',
                        type: 'item',
                        url: '/history/driver-work-report',
                        faIcon: faFileContract
                    },
                    {
                        id: 'agencyDriverReport',
                        title: 'Agency Driver Report',
                        translate: 'NAV.AGENCY_DRIVER_REPORT.TITLE',
                        type: 'item',
                        url: '/history/agency-driver-report',
                        faIcon: faFileContract
                    },
                    {
                        id: 'availableDriverReport',
                        title: 'Available Drivers',
                        translate: 'NAV.AVAILABLE_DRIVER_REPORT.TITLE',
                        type: 'item',
                        url: '/history/available-driver-report',
                        faIcon: faFileContract
                    }
                ]
            },
            {
                id: 'subcontractorReports',
                title: 'Subcontractor Reports',
                translate: 'NAV.SUBCONTRACTOR_REPORTS.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'subContractedWorkReport',
                        title: 'Subcontracted Work Report',
                        translate: 'NAV.SUBCONTRACTED_WORK_REPORT.TITLE',
                        type: 'item',
                        url: '/history/subcontracted-work-report',
                        faIcon: faFileContract
                    },
                    {
                        id: 'subcontractHistoryReport',
                        title: 'Subcontract History Report',
                        translate: 'NAV.SUBCONTRACT_HISTORY_REPORT.TITLE',
                        type: 'item',
                        url: '/history/subcontract-history-report',
                        faIcon: faFileContract
                    },
                    {
                        id: 'subcontractProfitabilityReport',
                        title: 'Subcontract Profitability Report',
                        translate: 'NAV.SUBCONTRACTOR_PROFITABILITY_REPORT.TITLE',
                        type: 'item',
                        url: '/history/subcontract-profitability-report',
                        faIcon: faFileContract
                    }
                ]
            },
            {
                id: 'pricingReport',
                title: 'Pricing Report',
                translate: 'NAV.PRICING_REPORT.TITLE',
                type: 'item',
                url: '/history/pricing-report',
                faIcon: faFileInvoiceDollar
            },
            {
                id: 'emptyRunningReport',
                title: 'Empty Running Report',
                translate: 'NAV.EMPTY_RUNNING_REPORT.TITLE',
                type: 'item',
                url: '/history/empty-running-report',
                faIcon: faBatteryEmpty
            },
            {
                id: 'extraRevenueReport',
                title: 'Extra Revenue Report',
                translate: 'NAV.EXTRA_REVENUE_REPORT.TITLE',
                type: 'item',
                url: '/history/extra-revenue-report',
                faIcon: faFileInvoiceDollar
            },
            {
                id: 'resourceRevenueReport',
                title: 'Resource Work Report',
                translate: 'NAV.RESOURCE_WORK_REPORT.TITLE',
                type: 'item',
                url: '/history/resource-work-report',
                faIcon: faFileContract
            },
            {
                id: 'acknowledgeReport',
                title: 'Acknowledge Report',
                translate: 'NAV.ACKNOWLEDGE_REPORT.TITLE',
                type: 'item',
                url: '/history/acknowledge-report',
                faIcon: faFileContract
            }
        ]
    },
    {
        id: 'Setup',
        title: 'Setup',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        faIcon: faStar,
        children: [
            {
                id: 'driver-management',
                title: 'Driver Management',
                translate: 'NAV.DRIVERMANAGEMENT.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'driver',
                        title: 'Drivers',
                        translate: 'NAV.DRIVERS.TITLE',
                        type: 'item',
                        url: '/setup/drivers',
                        faIcon: faTruck
                    },
                    {
                        id: 'driver-type',
                        title: 'Driver Types',
                        translate: 'NAV.DRIVER_TYPE.TITLE',
                        type: 'item',
                        url: '/setup/driver-types',
                        faIcon: faStar
                    },
                    {
                        id: 'handheld-profile',
                        title: 'Handheld Profiles',
                        translate: 'NAV.HANDHELD_PROFILE.TITLE',
                        type: 'item',
                        faIcon: faIdBadge,
                        url: '/setup/handheld-profiles'
                    },
                    {
                        id: 'driver-sub-type',
                        title: 'Driver Sub Types',
                        translate: 'NAV.DRIVER_SUB_TYPES.TITLE',
                        type: 'item',
                        faIcon: faFileContract,
                        url: '/setup/driver-sub-types'
                    },
                    {
                        id: 'driver-request-type',
                        title: 'Driver Request Types',
                        translate: 'NAV.DRIVER_REQUEST_TYPES.TITLE',
                        type: 'item',
                        faIcon: faFileContract,
                        url: '/setup/driver-request-types'
                    },
                    {
                        id: 'driver-request',
                        title: 'Driver Requests',
                        translate: 'NAV.DRIVER_REQUESTS.TITLE',
                        type: 'item',
                        faIcon: faFileContract,
                        url: '/setup/driver-requests'
                    },
                    {
                        id: 'driverStrikeReport',
                        title: 'Driver Strike Report',
                        translate: 'NAV.DRIVER_STRIKE_REPORT.TITLE',
                        type: 'item',
                        url: '/setup/driver-strike-report',
                        faIcon: faBan
                    },
                    {
                        id: 'driver-break',
                        title: 'Driver Breaks',
                        translate: 'NAV.DRIVER_BREAK.TITLE',
                        type: 'item',
                        url: '/setup/driver-break',
                        faIcon: faCoffee
                    }
                ]
            },
            {
                id: 'vehicle-management',
                title: 'Vehicle Management',
                translate: 'NAV.VEHICLEMANAGEMENT.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'vehicle',
                        title: 'Vehicles',
                        translate: 'NAV.VEHICLE.TITLE',
                        type: 'item',
                        icon: 'local_shipping',
                        url: '/setup/vehicles',
                        faIcon: faTruckMoving
                    },
                    {
                        id: 'vehicle-class',
                        title: 'Vehicle Classes',
                        translate: 'NAV.VEHICLE_CLASS.TITLE',
                        type: 'item',
                        icon: 'style',
                        url: '/setup/vehicle-classes',
                        faIcon: faTruckPickup
                    },
                    {
                        id: 'trailer',
                        title: 'Trailers',
                        translate: 'NAV.TRAILER.TITLE',
                        type: 'item',
                        url: '/setup/trailers',
                        faIcon: faTrailer
                    },
                    {
                        id: 'trailer-type',
                        title: 'Trailer Types',
                        translate: 'NAV.TRAILER_TYPE.TITLE',
                        type: 'item',
                        icon: 'style',
                        url: '/setup/trailer-types',
                        faIcon: faCaravan
                    },
                    {
                        id: 'safety-profile',
                        title: 'Safety Profiles',
                        translate: 'NAV.SAFETY_PROFILE.TITLE',
                        type: 'item',
                        url: '/vehicle-inspections/safety-profiles',
                        faIcon: faHardHat
                    }
                ]
            },
            {
                id: 'organisation-management',
                title: 'Organisation Management',
                translate: 'NAV.ORGANISATIONMANAGEMENT.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'roles',
                        title: 'Roles',
                        translate: 'NAV.ROLES.TITLE',
                        type: 'item',
                        url: '/setup/roles',
                        icon: 'person'
                    },
                    {
                        id: 'translation',
                        title: 'Translation',
                        translate: 'NAV.TRANSLATION.TITLE',
                        type: 'item',
                        url: '/setup/translation',
                        icon: 'person'
                    },
                    {
                        id: 'users',
                        title: 'Users',
                        translate: 'NAV.USERS.TITLE',
                        type: 'item',
                        url: '/setup/users',
                        faIcon: faIdBadge
                    },
                    {
                        id: 'vat-type',
                        title: 'VAT Types',
                        translate: 'NAV.VAT_TYPE.TITLE',
                        type: 'item',
                        url: '/setup/vat-types',
                        faIcon: faBoxOpen
                    },
                    {
                        id: 'exchange-rate',
                        title: 'Exchange Rates',
                        translate: 'NAV.EXCHANGE_RATE.TITLE',
                        type: 'item',
                        url: '/setup/exchange-rates',
                        faIcon: faExchangeAlt
                    },
                    {
                        id: 'country',
                        title: 'Country',
                        translate: 'NAV.COUNTRY.TITLE',
                        type: 'item',
                        url: '/setup/country',
                        faIcon: faPallet
                    },

                    // {
                    //     id: "service-level",
                    //     title: "Service Levels",
                    //     translate: "NAV.SERVICE_LEVEL.TITLE",
                    //     type: "item",
                    //     url: "/setup/service-levels",
                    //     faIcon: faTasks,
                    // },
                    {
                        id: 'custom-goods-type',
                        title: 'Goods Types',
                        translate: 'NAV.CUSTOM_GOODS_TYPE.TITLE',
                        type: 'item',
                        url: '/tariff/custom-goods-types',
                        faIcon: faBoxOpen
                    },
                    // {
                    //     id: "nominal-code",
                    //     title: "Nominal Codes",
                    //     translate: "NAV.NOMINAL_CODE.TITLE",
                    //     type: "item",
                    //     url: "/setup/nominal-codes",
                    //     faIcon: faStar,
                    // },
                    // {
                    //     id: "extra-type",
                    //     title: "Extra Types",
                    //     translate: "NAV.EXTRA_TYPE.TITLE",
                    //     type: "item",
                    //     url: "/setup/extra-types",
                    //     faIcon: faStar,
                    // },
                    // {
                    //     id: 'extra',
                    //     title: 'Extras',
                    //     translate: 'NAV.EXTRA.TITLE',
                    //     type: 'item',
                    //     url: '/setup/extras',
                    //     faIcon: faStar
                    // },
                    // {
                    //     id: "scale",
                    //     title: "Scales",
                    //     translate: "NAV.SCALE.TITLE",
                    //     type: "item",
                    //     url: "/setup/scales",
                    //     faIcon: faStar,
                    // },
                    {
                        id: 'business-type',
                        title: 'Business Types',
                        translate: 'NAV.BUSINESS_TYPE.TITLE',
                        type: 'item',
                        url: '/setup/business-types',
                        faIcon: faStar
                    },
                    // {
                    //     id: "fuel-surcharge-group",
                    //     title: "Fuel Surcharge Groups",
                    //     translate: "NAV.FUEL_SURCHARGE_GROUP.TITLE",
                    //     type: "item",
                    //     url: "/setup/fuel-surcharge-groups",
                    //     faIcon: faPercent,
                    // },
                    {
                        id: 'organisation',
                        title: 'Organisations',
                        translate: 'NAV.ORGANISATION.TITLE',
                        type: 'item',
                        url: '/setup/organisations',
                        faIcon: faBuilding
                    },
                    {
                        id: 'contact-info',
                        title: 'Contact Info',
                        translate: 'NAV.CONTACT_INFO.TITLE',
                        type: 'item',
                        url: '/setup/contact-info',
                        faIcon: faAddressCard
                    },
                    {
                        id: 'location-point',
                        title: 'Location Points',
                        translate: 'NAV.LOCATION_POINT.TITLE',
                        type: 'item',
                        url: '/setup/location-points',
                        faIcon: faMapPin
                    },
                    {
                        id: 'traffic-areas',
                        title: 'Traffic Areas',
                        translate: 'NAV.TRAFFIC_AREA.TITLE',
                        type: 'item',
                        url: '/setup/traffic-areas',
                        faIcon: faMapPin
                    },
                    {
                        id: 'control-areas',
                        title: 'Control Areas',
                        translate: 'NAV.CONTROL_AREA.TITLE',
                        type: 'item',
                        url: '/setup/control-areas',
                        faIcon: faMapPin
                    },
                    {
                        id: 'expense-type',
                        title: 'Expense Types',
                        translate: 'NAV.EXPENSE_TYPE.TITLE',
                        type: 'item',
                        url: '/setup/expense-types',
                        faIcon: faMoneyCheckAlt
                    },
                    {
                        id: 'expense-payment-method',
                        title: 'Expense Payment Method',
                        translate: 'NAV.EXPENSE_PAYMENT_METHOD.TITLE',
                        type: 'item',
                        url: '/setup/expense-payment-method',
                        faIcon: faMoneyCheckAlt
                    },
                    {
                        id: 'event-type',
                        title: 'Event Types',
                        translate: 'NAV.EVENT_TYPE.TITLE',
                        type: 'item',
                        url: '/setup/event-types',
                        faIcon: faBell
                    },
                    {
                        id: 'resource-event-type',
                        title: 'Resource Event Types',
                        translate: 'NAV.RESOURCE_EVENT_TYPE.TITLE',
                        type: 'item',
                        url: '/leg-planning/resource-event-types',
                        faIcon: faStar
                    },
                    {
                        id: 'resource-icons',
                        title: 'Resource Icons',
                        translate: 'NAV.RESOURCE_ICONS.TITLE',
                        type: 'item',
                        url: '/setup/resource-icons',
                        faIcon: faShapes
                    },

                    {
                        id: 'shift-types',
                        title: 'Shift Types',
                        translate: 'NAV.SHIFT_TYPES.TITLE',
                        type: 'item',
                        url: '/setup/shift-types',
                        faIcon: faStar
                    },
                    {
                        id: 'teams',
                        title: 'Teams',
                        translate: 'NAV.TEAMS.TITLE',
                        type: 'item',
                        url: '/setup/teams',
                        faIcon: faUsers
                    },
                    {
                        id: 'late-reasons',
                        title: 'Late Reasons',
                        translate: 'NAV.LATE_REASON.TITLE',
                        type: 'item',
                        url: '/setup/late-reasons',
                        faIcon: faHourglassHalf
                    },
                    {
                        id: 'deviation-reasons',
                        title: 'Deviation Reasons',
                        translate: 'NAV.DEVIATION_REASON.TITLE',
                        type: 'item',
                        url: '/setup/deviation-reasons',
                        faIcon: faHourglassHalf
                    },
                    {
                        id: 'claused-delivery-reasons',
                        title: 'Claused Delivery Reasons',
                        translate: 'NAV.CLAUSED_DELIVERY_REASON.TITLE',
                        type: 'item',
                        url: '/setup/claused-delivery-reasons',
                        faIcon: faAsterisk
                    },
                    {
                        id: 'accounts-package',
                        title: 'Accounts Package Setup',
                        translate: 'NAV.ACCOUNTS_PACKAGE.TITLE',
                        type: 'item',
                        faIcon: faTasks,
                        url: '/setup/accounts-package'
                    },
                    {
                        id: 'multi-trunk',
                        title: 'Multi-Trunk Setup',
                        translate: 'NAV.MULTI_TRUNK.TITLE',
                        type: 'item',
                        faIcon: faTasks,
                        url: '/setup/multi-trunks'
                    }
                ]
            },
            {
                id: 'tariff',
                title: 'Tariff Management',
                translate: 'NAV.TARIFF.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'scale',
                        title: 'Scales',
                        translate: 'NAV.SCALE.TITLE',
                        type: 'item',
                        url: '/tariff/scales',
                        faIcon: faStar
                    },
                    {
                        id: 'system-surcharges',
                        title: 'System Fuel Surcharges',
                        translate: 'NAV.SYSTEM_SURCHARGES.TITLE',
                        type: 'item',
                        url: '/setup/system-surcharges',
                        faIcon: faPercent
                    },
                    {
                        id: 'fuel-surcharge-group',
                        title: 'Fuel Surcharge Groups',
                        translate: 'NAV.FUEL_SURCHARGE_GROUP.TITLE',
                        type: 'item',
                        url: '/tariff/fuel-surcharge-groups',
                        faIcon: faPercent
                    },
                    {
                        id: 'extra-type',
                        title: 'Extra Types',
                        translate: 'NAV.EXTRA_TYPE.TITLE',
                        type: 'item',
                        url: '/tariff/extra-types',
                        faIcon: faStar
                    },
                    {
                        id: 'service-level',
                        title: 'Service Levels',
                        translate: 'NAV.SERVICE_LEVEL.TITLE',
                        type: 'item',
                        url: '/tariff/service-levels',
                        faIcon: faTasks
                    },
                    {
                        id: 'nominal-code',
                        title: 'Nominal Codes',
                        translate: 'NAV.NOMINAL_CODE.TITLE',
                        type: 'item',
                        url: '/tariff/nominal-codes',
                        faIcon: faStar
                    },
                    // {
                    //     id: "custom-goods-type",
                    //     title: "Custom Goods Types",
                    //     translate: "NAV.CUSTOM_GOODS_TYPE.TITLE",
                    //     type: "item",
                    //     url: "/tariff/custom-goods-types",
                    //     faIcon: faBoxOpen,
                    // },
                    // {
                    //     id: 'price-list',
                    //     title: 'Price Lists',
                    //     translate: 'NAV.PRICE_LIST.TITLE',
                    //     type: 'item',
                    //     url: '/tariff/price-lists',
                    //     faIcon: faStar
                    // },
                    {
                        id: 'zone-map',
                        title: 'Zone Map',
                        translate: 'NAV.ZONE_MAP.TITLE',
                        type: 'item',
                        url: '/tariff/zone-maps',
                        faIcon: faStar
                    },
                    {
                        id: 'tariff-list',
                        title: 'Tariff Lists',
                        translate: 'NAV.PRICE_LIST.TITLE',
                        type: 'item',
                        url: '/tariff/price-lists',
                        faIcon: faStar
                    }
                ]
            },
            {
                id: 'tourplanning',
                title: 'Tour Planning',
                translate: 'NAV.TOUR_PLAN.TITLE',
                type: 'collapsable',
                icon: 'supervisor_account',
                children: [
                    {
                        id: 'tp-asset-profile',
                        title: 'Asset Profiles',
                        translate: 'NAV.TP_ASSET_PROFILE.TITLE',
                        type: 'item',
                        url: '/setup/tp-asset-profiles',
                        faIcon: faStar
                    },
                    {
                        id: 'tp-vehicle-skill',
                        title: 'Vehicle Skills',
                        translate: 'NAV.TP_VEHICLE_SKILL.TITLE',
                        type: 'item',
                        url: '/setup/tp-vehicle-skills',
                        faIcon: faStar
                    },
                    {
                        id: 'tp-vehicle-type',
                        title: 'Tp Vehicle Types',
                        translate: 'NAV.TP_VEHICLE_TYPE.TITLE',
                        type: 'item',
                        url: '/setup/tp-vehicle-types',
                        faIcon: faStar
                    }
                ]
            },
            {
                id: 'image-management',
                title: 'Image Management',
                translate: 'NAV.IMAGE_MANAGEMENT.TITLE',
                type: 'item',
                url: '/setup/image-management',
                faIcon: faTasks
            }
            // {
            //     id: 'task-management',
            //     title: 'Task Management',
            //     translate: 'NAV.TASK_MANAGEMENT.TITLE',
            //     type: 'item',
            //     url: '/setup/task-management',
            //     faIcon: faTasks
            // },
            // {
            //     id: 'file-management',
            //     title: 'File Management',
            //     translate: 'NAV.FILE_MANAGEMENT.TITLE',
            //     type: 'item',
            //     url: '/setup/file-management',
            //     faIcon: faTasks
            // }
        ]
    },
    {
        id: 'requests',
        title: 'Requests',
        translate: 'NAV.REQUESTS.TITLE',
        type: 'group',
        icon: 'bar_chart',
        children: [
            {
                id: 'app-requests',
                title: 'App Requests',
                translate: 'NAV.APP_REQUESTS.TITLE',
                type: 'item',
                url: '/history/app-requests',
                icon: 'bar_chart'
            },
            {
                id: 'async-requests',
                title: 'Async Requests',
                translate: 'NAV.ASYNC_REQUESTS.TITLE',
                type: 'item',
                url: '/setup/async-requests',
                icon: 'bar_chart'
            }
        ]
    },
    {
        id: 'audits',
        title: 'Audits',
        translate: 'NAV.AUDIT.TITLE',
        type: 'group',
        faIcon: faBookOpen,
        children: [
            {
                id: 'mail-audits',
                title: 'Mail Audit',
                translate: 'NAV.MAIL_AUDIT.TITLE',
                type: 'item',
                url: '/history/mail-audits',
                icon: 'bar_chart'
            },
            {
                id: 'auditLog',
                title: 'Audit Log',
                translate: 'NAV.AUDIT_LOG.TITLE',
                type: 'item',
                url: '/history/audit-log',
                icon: 'import_contacts'
            },
            {
                id: 'podAudit',
                title: 'Pod Audit',
                translate: 'NAV.POD_AUDIT.TITLE',
                type: 'item',
                url: '/setup/pod-audit',
                faIcon: faBookOpen
            }
        ]
    },
    {
        id: 'workflows',
        title: 'Workflows',
        translate: 'NAV.WORKFLOW.TITLE',
        type: 'group',
        icon: 'share',
        children: [
            {
                id: 'email-workflows',
                title: 'Email Workflows',
                translate: 'NAV.EMAIL_WORKFLOW.TITLE',
                type: 'item',
                icon: 'share',
                url: '/setup/email-workflows'
            },
            {
                id: 'delete-workflows',
                title: 'Delete Workflows',
                translate: 'NAV.DELETE_WORKFLOW.TITLE',
                type: 'item',
                icon: 'share',
                url: '/setup/delete-workflows'
            },
            {
                id: 'update-workflows',
                title: 'Update Workflows',
                translate: 'NAV.UPDATE_WORKFLOW.TITLE',
                type: 'item',
                icon: 'share',
                url: '/setup/update-workflows'
            }
        ]
    },
    {
        id: 'import',
        title: 'IMPORT / EXPORT',
        translate: 'NAV.IMPEX',
        type: 'group',
        faIcon: faFileUpload,
        children: [
            {
                id: 'import-data',
                title: 'Import Data',
                translate: 'NAV.IMPORT_DATA.TITLE',
                type: 'item',
                url: '/setup/import-data',
                faIcon: faFileUpload
            },
            {
                id: 'import-orders',
                title: 'Import Orders',
                translate: 'NAV.IMPORT_ORDER.TITLE',
                type: 'item',
                url: '/setup/import-orders',
                faIcon: faFileUpload
            },
            {
                id: 'haulier-order-awaiting-integration',
                title: 'Orders Awaiting Integration',
                translate: 'NAV.HAULIER_ORDER_AWAITING_INTEGRATION.TITLE',
                type: 'item',
                url: '/orders/haulier-order-awaiting-integration',
                faIcon: faPallet
            },
            {
                id: 'metsa-order',
                title: 'Metsa Order Screen',
                translate: 'NAV.METSA_ORDER.TITLE',
                type: 'item',
                url: '/orders/metsa-order',
                faIcon: faPallet
            },
            {
                id: 'haulier-order-failed-integration',
                title: 'Failed Integration Orders',
                translate: 'NAV.HAULIER_ORDER_FAILED_INTEGRATION.TITLE',
                type: 'item',
                url: '/orders/haulier-order-failed-integration',
                faIcon: faPallet
            },
            {
                id: 'location-point-awaiting-integration',
                title: 'Points for Integration',
                translate: 'NAV.LOCATION_POINT_AWAITING_INTEGRATION.TITLE',
                type: 'item',
                url: '/setup/location-points-awaiting-integration',
                faIcon: faMapPin
            },
            {
                id: 'reindex',
                title: 'Search Re-index',
                translate: 'NAV.ADMIN_REINDEX.TITLE',
                type: 'item',
                url: '/setup/admin/reindex',
                faIcon: faBell
            },
            {
                id: 'LocalClient',
                title: 'Local Client',
                translate: 'NAV.LOCALCLIENT.TITLE',
                type: 'item',
                url: '/LocalClients',
                faIcon: faStar
            }
        ]
    }
];

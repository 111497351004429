// sqs.service.ts

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';
import {Principal} from 'app/core/auth/principal.service';
import {ca} from 'date-fns/locale';
import {environment} from 'environments/environment';
import {Observable, filter} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LogService {
    tenant: any;
    userId: any;
    role: any;
    authority: any;
    name: any;
    email: any;
    tabId: any;
    constructor(
        private _principal: Principal,
        private http: HttpClient,
        private router: Router,
        private newrelicAgent?: BrowserAgent
    ) {
        this.tabId = new Date().getTime();
        this._principal.getAuthenticationState().subscribe((userIdentity) => {
            if (userIdentity) {
                this.tenant = userIdentity.tenant;
                this.userId = userIdentity.id;
                this.role = userIdentity.roles;
                this.authority = userIdentity.authorities;
                this.name = userIdentity.firstName + ' ' + userIdentity.lastName;
                this.email = userIdentity.email;
            }
        });
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.log('Page View', {
                tabId: this.tabId,
                viewOrder: event.id
            });
        });
    }

    log(action: any, metadata: any): void {
        try {
            this.newrelicAgent?.addPageAction(action, {
                ...metadata,
                userId: this.userId,
                role: this.role,
                authority: this.authority,
                username: this.name,
                email: this.email
            });
        } catch (error) {
            console.error(error);
        }
    }
}

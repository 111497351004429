export const locale = {
    lang: 'en',
    data: {
        haulierOrdersPendingApproval: {
            pages: {
                list: {
                    heading: 'Awaiting Orders',
                    headings: ['Collections For Planning', 'Deliveries For Planning', 'All Orders'],
                    columns: {
                        id: 'ID',
                        name: 'Name',
                        runIds: 'Runs',
                        orderNo: 'Order Number',
                        loadNo: 'Load No',
                        customer: 'Customer',
                        collectionPointTown: 'Collection Point Town',
                        collectionDate: 'Collection Date',
                        deliveryDate: 'Delivery Date',
                        collectionAddress1: 'Coll Addr',
                        bookingEmailSent: 'Booking Email Sent',
                        collectionPoint: 'Collection',
                        collectAt: 'Collect At',
                        deliveryPointTown: 'Delivery Point Town',
                        deliveryAddress1: 'Deliv Addr',
                        deliveryPoint: 'Delivery',
                        vin: 'Vin',
                        make: 'Make',
                        year: 'Year',
                        model: 'Model',
                        pallets: 'Pallets',
                        spaces: 'Spaces',
                        kg: 'Kg',
                        goodsType: 'Goods Type',
                        orderStatus: 'Order Status',
                        delivered: 'Delivered',
                        pdf: 'PDF',
                        rate: 'Rate',
                        foreignRate: 'Foreign Rate',
                        fuelSurcharge: 'Fuel Surcharge',
                        extrasFuelSurcharge: 'Extras Surcharge',
                        extras: 'Extras',
                        readyToInvoice: 'Ready for Invoice',
                        pod: 'POD',
                        orderGroup: 'Order Group'
                    },
                    rowActions: {
                        view: 'View',
                        edit: 'Edit',
                        delete: 'Delete'
                    },
                    groupActions: {},
                    masterCheckboxActions: {},
                    buttons: {
                        add: 'ADD'
                    },
                    key: {
                        NO_PODS: 'No Pod Assigned'
                    }
                }
            },
            scopes: {
                default: {
                    enums: {
                        orderStatus: {
                            PENDING: 'Pending',
                            AWAITING_APPROVAL: 'Awaiting Approval',
                            APPROVED: 'Approved',
                            IN_PROGRESS: 'In-Progress',
                            DELIVERED: 'Delivered',
                            INVOICED: 'Invoiced',
                            CANCELLED: 'Cancelled',
                            REJECTED: 'Rejected'
                        }
                    }
                }
            }
        },
        outstandingCallIns: {
            pages: {
                list: {
                    heading: 'Outstanding Call-Ins',
                    activeFilters: ' Filters Active',
                    columns: {
                        runId: 'Run ID',
                        orders: 'Orders',
                        fromStop: 'From',
                        toStop: 'To',
                        driver: 'Driver',
                        vehicle: 'Vehicle',
                        trailer: 'Trailer',
                        subcontractor: 'Subcontractor',
                        startTime: 'Start Time',
                        endTime: 'Finish Time',
                        actions: 'Actions'
                    }
                }
            }
        },
        awaitingPoints: {
            main: {
                heading: 'Points for Integration',
                fields: {
                    source: 'Source',
                    organisationName: 'Collect From',
                    addressKey: 'Address Data Received from Source',
                    SMURFIT_ORDER_DELIVERY_ADDRESS: 'Smurfit order',
                    SWGROUP_ORDER_DELIVERY_ADDRESS: 'SW Group Order'
                }
            },
            view: {
                fields: {
                    source: 'Source',
                    organisationName: 'Collect From',
                    addressKey: 'Address Data Received from Source',
                    SWGROUP_ORDER_DELIVERY_ADDRESS: 'SW Group Order',
                    SMURFIT_ORDER_DELIVERY_ADDRESS: 'Smurfit order'
                }
            }
        }
    }
};
